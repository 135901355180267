import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import friends from '../../../assets/images/landing-page/friends.png'
import syllabus from '../../../assets/images/landing-page/syllabus.svg'
import Jobs from '../../../assets/images/landing-page/Jobs.svg'
import success from '../../../assets/images/landing-page/success.svg'
import result_background from '../../../assets/images/landing-page/result-background.png'
import principal from '../../../assets/images/landing-page/principal.png'
import Vector from '../../../assets/images/landing-page/Vector.png'
import egal from '../../../assets/images/landing-page/egal.png'
import banner_video from '../../../assets/video/banner-video.mp4'
import { NavLink, Link } from "react-router-dom";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import admission_banner_1 from "../../../assets/images/admission/admission-banner-1.jpg";
import admission_banner_2 from "../../../assets/images/admission/admission-banner-2.jpg";
import admission_banner_3 from "../../../assets/images/admission/admission-banner-3.jpg";
import admission_banner_4 from "../../../assets/images/admission/admission-banner-4.jpg";
import { CountUpNumber } from '../General/Utility';
import Carousel from 'react-bootstrap/Carousel';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Home = () => {

  document.title = "DPS Kasur & Collage";

  useEffect(() => {
    AOS.init();
  }, []);

  const [AccessKey, setAccessKey] = useState("239f5be8-5389-44ea-8935-b5fe638339da");
  const [UserID, setUserID] = useState("1");
  const [MemberType, setMemberType] = useState("Website");

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfNews, setNews] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [listOfEvents, LedgerEvents] = useState([]);
  const [listOfPhoto, LedgerPhoto] = useState([]);
  const [Message, setMessage] = useState([]);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  const [Title, setTitle] = useState(0);

  const [Month, setMonth] = useState(moment().format("M"));
  const [Year, setYear] = useState(moment().format("YYYY"));

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      Title: Title,
      MemberType: MemberType,
      pageSize: pageSize,
    };
    //console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "News/GetAllNews",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setNews(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Title]);

  useEffect(() => {

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 3,
      Title: Title,
    };
    //console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "Events/GetAllEvents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        LedgerEvents(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Title]);


  useEffect(() => {

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 6,
    };
    //console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "PhotoGallery/GetAllPhoto",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        LedgerPhoto(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Title]);

  // GetAllMainMessagesWithoutKey -----------

  useEffect(() => {

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 6,
    };
    //console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "MainMessage/GetAllMainMessagesWithoutKey",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setMessage(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);



  const renderNews = () => {
    if (listOfNews != null && listOfNews.length > 0) {
      let count = 1;
      return listOfNews.slice(0, 3).map((item, index) => {
        return (
          <li
            key={index}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <Link onClick={scrollToTop} to={"/Home/NewsDetail/" + item.ID} className="sch-card">
              <span><i class="ri-megaphone-fill"></i></span>
              <div className="text">
                <h1>
                  {item.Title.length > 55
                    ? `${item.Title.substring(0, 55)}...`
                    : item.Title}
                </h1>
                <p className="">
                  {item.Short_Description.length > 180
                    ? `${item.Short_Description.substring(0, 180)}...`
                    : item.Short_Description}
                </p>
              </div>
              <div className="read-more">
                <div className="date">
                  <i className="ri-calendar-line"></i>
                  <span className="">{moment(item.Dated).format('DD-MMM-YYYY')}</span>
                </div>
                <div className="">
                  <Link onClick={scrollToTop} className="" to={"/Home/NewsDetail/" + item.ID}>
                    Read More <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>
            </Link>
          </li>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: '15px', color: 'rgba(0,0,0,0.6)' }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  // http://api.dpskasur.edu.pk/Events/

  const renderEvents = () => {
    if (listOfEvents != null && listOfEvents.length > 0) {

      return listOfEvents.map((item, index) => {
        return (
          <>
            <li
              key={index}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div className="events-card">
                <div>
                  <img className="img-fluid rounded-top" alt="..." src={config.images + "Events/" + item.Thumbnail} />
                </div>
                <div className="rounded-bottom detail">
                  <div className="card-detail">
                    <div className="date">
                      <i className="ri-calendar-line"></i> <span className="">{moment(item.Updated_Date).format('DD-MMM-YYYY')}</span>
                    </div>
                    <h5>
                      {item.Title.length > 55
                        ? `${item.Title.substring(0, 55)}...`
                        : item.Title}
                    </h5>
                  </div>
                  <Link onClick={scrollToTop} to={"/Home/EventDetail/" + item.ID} className="read-more">
                    <div> READ MORE</div>
                    <div className="ri-arrow-right-line"></div>
                  </Link>
                </div>
              </div>
            </li>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderPhoto = () => {
    if (listOfPhoto != null && listOfPhoto.length > 0) {

      return listOfPhoto.map((item, index) => {
        return (
          <>
            <li
              key={index}
              data-aos="fade-up"
              data-aos-duration="500"
            >

              <Link onClick={scrollToTop} to={`/Home/PhotoDetail/` + item.ID}>
                <div className="photo-card">
                  <img className="img-fluid rounded-top" alt="..." src={config.images + "photogallery/" + item.Photo_Thumbnail} />
                  <div className="card-body">
                    <p className="card-text">
                      {item.Photo_Title.length > 55
                        ? `${item.Photo_Title.substring(0, 55)}...`
                        : item.Photo_Title}
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderMessage = () => {
    if (Message && Message.length > 0) {
      return Message.map((item, index) => (
        <Carousel.Item key={index}>
          <div className="row">
            <div className="col-md-6 offset-md-4 col-sm-12">
              <div className="massage-detail">
                <div className="profile">
                  <img src={config.images + 'Images/MainMessage/' + item.Photo} alt="" />
                </div>
                <div className="massage">
                  <h1>{item.Title}</h1>
                  <div dangerouslySetInnerHTML={{ __html: item.Details }} />
                </div>
              </div>
              <div className="profile">
                <img src={config.images + 'Images/MainMessage/' + item.Photo} alt="" />
              </div>
              <div className="dots">
                <img src={Vector} alt="" />
              </div>
            </div>
          </div>
        </Carousel.Item>
      ));
    } else {
      return (
        <Carousel.Item>
          <h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>
            No Data Found
          </h4>
        </Carousel.Item>
      );
    }
  };


  const [Messageindex, setMessageIndex] = useState(0);
  const [Admissionindex, setAdmissionIndex] = useState(0);



  return (
    <>
      <ToastContainer />
      <section>
        <div>
          <video src={banner_video} style={{ width: "100%" }} muted autoPlay loop></video>
          <div className="hero-text" data-aos="fade-up" data-aos-delay="100">
            <h4>WELCOME TO</h4>
            <h1>CYNOSURE OF KNOWLEDGE</h1>
            <p>
              The school is one of its kind in the whole district. Its
              spacious grounds and beautiful lawns lend an enchanting beauty
              and leave lasting imprints of broadmindedness on the mind of
              students.
            </p>
            <div>
              <Link onClick={scrollToTop} className="button-icon button-secondary" to="/Home/Introduction/">
                Read More
                <span><i className="ri-arrow-right-s-line"></i></span>
              </Link>
            </div>
          </div>
        </div>


      </section>
      <section className="simple-section vission">
        <div className="container">
          <div className="row">
            <div className="col-md-4" data-aos="fade-right" data-aos-delay="200">
              <div className="vision">
                <h5>ABOUT INSTITUTE</h5>
                <h3>
                  Clear vision of leadership and character building would be
                  especially focused
                </h3>
                <p></p>
                <ul>
                  <li>
                    <i className="ri-checkbox-circle-fill"></i> Play Grounds
                  </li>
                  <li>
                    <i className="ri-checkbox-circle-fill"></i> Computer Lab
                  </li>
                  <li>
                    <i className="ri-checkbox-circle-fill"></i> Library
                  </li>
                  <li>
                    <i className="ri-checkbox-circle-fill"></i> Transport
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="position-relative">
                <div>
                  <img className="img-fluid" src={egal} alt="" />
                </div>
                <div className="success">
                  <div>
                    <h1>34</h1>
                    <span>YEARS OF SUCCESS</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-left" data-aos-delay="400">
              <div className="rating text-md-end text-sm-center">
                <div className="student">
                  <h2><CountUpNumber end={6700} />+</h2>
                  <h4>Total Students</h4>
                </div>
                <div className="staff">
                  <h2><CountUpNumber end={418} />+</h2>
                  <h4>Staff Members</h4>
                </div>
                <div className="transport">
                  <h2><CountUpNumber end={15} />+</h2>
                  <h4>Transport Busses</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="simple-section bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12" data-aos="fade-right">
              <div className="section-title">
                <h3 className="">Over the Last 34 Years</h3>
              </div>
              <div className="section-detail">
                <p>
                  <strong><b>District Public School &amp; College</b></strong> has taught, user-centered curriculum which focuses on Quality of Learning &amp; Student Well Being.
                </p>
                <div>
                  <Link onClick={scrollToTop} className="button-icon button-secondary" to="/Home/Introduction/" data-aos="fade-up">
                    About Us <span><i className="ri-arrow-right-s-line"></i></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12" data-aos="fade-left">
              <div className="photo dps">
                <img className="img-fluid" src={success} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='massage-container'>
        <div className="container">
          <Carousel activeIndex={Messageindex} onSelect={(selectedIndex) => setMessageIndex(selectedIndex)}>
            {renderMessage()}
          </Carousel>

        </div>
      </section >

      {/* <Meritlist/> */}
      <section className='admission-section'>
        <Carousel activeIndex={Admissionindex} onSelect={(selectedIndex, e) => setAdmissionIndex(selectedIndex)}>
          <Carousel.Item>
            <img src={admission_banner_1} alt="banner" className='w-100' />
          </Carousel.Item>
          <Carousel.Item>
            <img src={admission_banner_2} alt="banner" className='w-100' />
          </Carousel.Item>
          <Carousel.Item>
            <img src={admission_banner_3} alt="banner" className='w-100' />
          </Carousel.Item>
          <Carousel.Item>
            <img src={admission_banner_4} alt="banner" className='w-100' />
          </Carousel.Item>
        </Carousel>
      </section >
      <section className="simple-section" >
        <div className="container">
          <div className="row align-items-center mb-md-5">
            <div className="col-md-6">
              <div className="section-title">
                <h3>School News</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="school text-end">
                <Link onClick={scrollToTop} className="button-icon button-white" to="/Home/NewsList">
                  View All <span><i className="ri-arrow-right-s-line"></i></span>
                </Link>
              </div>
            </div>
          </div>

          {loading ? (
            <tr>
              <td className="text-center" colspan="9">
                <Spinner animation="border" role="status"></Spinner>
              </td>
            </tr>
          ) : (
            <>
              <ul className="responsive news-responsive">
                {renderNews()}
              </ul>
            </>
          )}
        </div>
      </section>
      <section className="simple-section bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center pb-sm-5">
            <div className="col-md-6 col-sm-12 pb-sm-4" data-aos="fade-up">
              <div className="section-title">
                <h3 className="" data-aos="fade-right">Work With Us</h3>
              </div>
              <div className="section-detail">
                <p data-aos="fade-right">
                  Our outstanding academic facilities create an exceptional working environment, where individuals come to teach, learn, and grow as valued professionals. DPS &amp; College Kasur is providing job opportunities to the developers of the District Kasur.
                </p>
                <div data-aos="fade-right">
                  <Link onClick={scrollToTop} className="button-icon button-secondary" to="/Home/Jobs">
                    View Jobs <span><i className="ri-arrow-right-s-line"></i></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12" data-aos="fade-up">
              <div className="photo">
                <img className="img-fluid" src={Jobs} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="simple-section result" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12" data-aos="fade-up">
              <div className="results-img">
                <img className="img-fluid" src={result_background} alt="" />
              </div>
            </div>
            <div className="col-md-6 col-sm-12" data-aos="fade-up">
              <div className="section-title mt-3">
                <div className="ps-md-5">
                  <h3>School Results</h3>
                  <h4>Striving to ensure our students reach their full potential.</h4>
                </div>
                <div className="section-detail">
                  <p>
                    We are proud of the outstanding academic progress of our students. Our results are a testament of hard work and dedication as well as the high level of commitment from our staff.
                  </p>
                  <div className="school">
                    <Link onClick={scrollToTop} className="button-icon button-white" to="/Examination/Results">
                      Check Results
                      <span><i className="ri-arrow-right-s-line"></i></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="simple-section bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6  col-sm-12">
              <div className="section-title">
                <h3 data-aos="fade-right">Syllabus</h3>
              </div>
              <div className="section-detail">
                <p data-aos="fade-right">
                  A syllabus is a plan that states exactly what a student at a school/college would learn in a particular subject. The students at DPS &amp; College study according to a well-planned and expertly designed syllabus.
                </p>
                <div data-aos="fade-right">
                  <Link onClick={scrollToTop} className="button-icon button-secondary" to="/Home/Syllabus">
                    Download Syllabus
                    <span><i className="ri-arrow-right-s-line"></i></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12" data-aos="fade-left">
              <div className="syllabus-img">
                <img className="img-fluid" src={syllabus} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="simple-section">
        <div className="events">
          <div className="container">
            <div className="row align-items-center mb-md-5">
              <div className="col-md-6">
                <div className="section-title">
                  <h3>Events at DPS</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="school text-end">
                  <Link onClick={scrollToTop} className="button-icon button-white" to="/Home/EventGallery">
                    View All <span><i className="ri-arrow-right-s-line"></i></span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="responsive">
                {renderEvents()}
              </div>
            </div>
          </div>
        </div>
      </section >
      <section className="simple-section bg-white">
        <div className="container">
          <div className="row align-items-center mb-md-5">
            <div className="col-md-6">
              <div className="section-title">
                <h3>Photo Gallery</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="school text-end">
                <Link onClick={scrollToTop} className="button-icon button-white" to="/Home/PhotoGallery">
                  View All <span><i className="ri-arrow-right-s-line"></i></span>
                </Link>
              </div>
            </div>
          </div>

          <ul className="responsive">
            {renderPhoto()}
          </ul>
        </div>
      </section >
      <section className="simple-section" data-aos="fade-up">
        <div className="sch-friend">
          <div className="container">
            <div className="friend">
              <div className="row">
                <div className="col-md-4" data-aos="fade-right">
                  <div className="child">
                    <h2>Ready to get Admission?</h2>
                    <Link onClick={scrollToTop} className="button-icon button-white" to="">
                      View Admissions
                      <span>
                        <i className="ri-arrow-right-s-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-7 offset-md-1" data-aos="fade-left">
                  <div>
                    <img className="img-fluid" src={friends} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home