import CountUp from 'react-countup';
export const RenderHTMLString = (htmlString) => {
  // Your function logic goes here
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

export const RenderHTMLStringWithoutDiv = (htmlString) => {
  // Your function logic goes here
  return (
    <div className='row p-0 m-0' dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

export function CountUpNumber({ end }) {
  return (
    <CountUp end={end} duration={1.5} separator="," decimal="." decimals={0} />
  );
};
export function calculateColumnTotal(data, columnName) {
  let total = 0;

  data.forEach((row) => {
    total += row[columnName];
  });

  return total;
}

export function getMonthName(monthNumber) {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return monthNames[monthNumber - 1];
}

export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};


export const replaceCharactersWithStars = (numberOfStars, inputString) => {

  if (!inputString) {
    return ''; // Return an empty string if inputString is undefined or null
  }

  const stars = '*'.repeat(numberOfStars);
  const replacedString = stars + inputString.slice(numberOfStars);

  return replacedString;
}
