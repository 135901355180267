import React, { useState } from 'react'
import List_icon from '../../../assets/images/list.svg'
import { Link } from 'react-router-dom'

const Meritlist = () => {
    const list = [
        { id: "1", label: "Computer Sci. (Senior Classes)- Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher Computer Sci. (Senior Classes)- Female.pdf" },
        { id: "2", label: "English (5th to 8th Classes) - M-F", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher English (5th to 8th Classes) - M-F.pdf" },
        { id: "3", label: "English (Junior Classes) - Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher English (Junior Classes) - Female.pdf" },
        { id: "4", label: "General Sc.-Math (Junior Classes) - Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher General Sc.-Math (Junior Classes) - Female.pdf" },
        { id: "5", label: "General Science (5th to 8th Classes) - M-F", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher General Science (5th to 8th Classes) - M-F.pdf" },
        { id: "6", label: "General Teacher (Junior Classes) - Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher General Teacher (Junior Classes) - Female.pdf" },
        { id: "7", label: "Islamiyat (5 th to 8th Classes) - Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher Islamiyat (5 th to 8th Classes) - Female.pdf" },
        { id: "8", label: "Maths (5th to 8th Classes) - MALE", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher Maths (5th to 8th Classes) - MALE.pdf" },
        { id: "9", label: "PTI (Junior Classes) Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher PTI (Junior Classes) Female.pdf" },
        { id: "10", label: "Social Studies (5th to 8th Classes) - Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher Social Studies (5th to 8th Classes) - Female.pdf" },
        { id: "11", label: "Urdu (5th to 8th Classes) - M-F", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher Urdu (5th to 8th Classes) - M-F.pdf" },
        { id: "12", label: "Urdu (Junior Classes) - Female", link: "https://www.dpskasur.edu.pk/Meritlist/Teacher Urdu (Junior Classes) - Female.pdf" },
        { id: "13", label: "معاون سوالات لیول2(اپردے کے بارے میں سوال و جواب ", link: "https://www.dpskasur.edu.pk/Meritlist/معاون سوالات لیول2(اپردے کے بارے میں سوال و جواب ) (1).pdf" },
    ];

    const [Merit, setMerit] = useState('')

    return (
        <>
            <section className="simple-section pb-0">
                <div className="container">
                    <div className="row mb-md-5">
                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div className="section-title">
                                <h3>Teacher Merit List</h3>
                            </div>
                        </div>
                    </div>
                    <div className="merit-card ">
                        <div className="row align-items-center gy-4">
                            <div className="col-md-4 text-center">
                                <img src={List_icon} className='img-fluid' alt="" />
                            </div>
                            <div className="col-md-6 offset-md-1">
                                <div className="form-group">
                                    <label htmlFor="" className="">Select Merit list:</label>
                                    <div className="content">
                                        <select name={Merit} className="form-select"
                                            onChange={(e) => setMerit(e.target.value)}
                                        >
                                            <option selected>Please Select</option>
                                            {list.map((item, index) => (
                                                <option key={item.id} value={item.link}>{item.label}</option>
                                            ))}
                                        </select>
                                        <Link target='blank' to={Merit} download className="button button-secondary">Download</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Meritlist;