import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import admission from '../../../assets/images/admission/admission.svg';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Admissions = () => {

    document.title = "Admissions";

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>Admissions</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link onClick={scrollToTop} to="/Home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Admissions
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section" data-aos="fade-up" data-aos-delay="500">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                            <img className="img-fluid" src={admission} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Admissions;
