import React, { useEffect } from 'react';
import bus from '../../../assets/images/Transport/bus.jpeg';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Transport = () => {
    document.title = "Transport";

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>TRANSPORT</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">TRANSPORT</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-sm-12" data-aos="fade-up" data-aos-duration="500">
                            <b>This facility is made available subject to the following conditions:</b>
                            <div className="promotion-policy">
                                <ul>
                                    <li data-aos="fade-up" data-aos-duration="500">Buses are operating on the specified routes of the city and its adjacent areas which have been determined after due consideration to various factors. So the requests for new routes or diversions for new stops will not be entertained.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">The students will be picked up/dropped from /at the specified stops according to the fixed timings. Buses will not wait for any one at the stops beyond the specified stoppage time.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">Parents will be themselves responsible to bring/collect their children to/from the relevant stops and ensure their security before/after they have been picked up/dropped by the school buses.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">The school reserves the right to make any change in the existing routes if necessitated by administrative reasons. However, parents will be informed well in advance as and when such a change is made.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">No concessions will be allowed to anyone in the bus fare including those availing full or half concession in the tuition fee/funds.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div>
                                <img className="img-fluid rounded-2"  data-aos="fade-up" data-aos-duration="500" src={bus} alt="" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="promotion-policy">
                                <ul>
                                    <li data-aos="fade-up" data-aos-duration="500">Bus facility may be withdrawn in case of any complaint of misconduct against a student or for non-payment of bus fare.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">In case of willful damage caused to the buses, the loss will be made good by the parents concerned in addition to the disciplinary action which may be taken against the respective students.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">Parents who want to discontinue the bus facility will be required to give in writing one month's notice to this effect failing which they will be charged the bus fare in lieu of the notice period.</li>
                                    <li data-aos="fade-up" data-aos-duration="500">Bus dues list will be provided later as per policy of the institution.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Transport