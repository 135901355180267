import CryptoJS from 'crypto-js';
import config from '../../Config';
export function crypto(props) {
  const integritySalt = config.IntegeritySalt;
  const secureHash = `${integritySalt}&${props.pp_Amount}&${props.pp_BillReference}&${props.pp_CNIC}&${props.pp_Description}&${props.pp_Language}&${props.pp_MerchantID}&${props.pp_MobileNumber}&${props.pp_Password}&${props.pp_TxnCurrency}&${props.pp_TxnDateTime}&${props.pp_TxnExpiryDateTime}&${props.pp_TxnRefNo}&${props.ppmpf_1}`;

  const secretBytes = CryptoJS.enc.Utf8.parse(integritySalt);
  const hash = CryptoJS.HmacSHA256(CryptoJS.enc.Latin1.parse(secureHash), secretBytes);
  const hexHash = CryptoJS.enc.Hex.stringify(hash);

  //console.log(secureHash, 'secureHash----');
  //console.log(hexHash, 'hexHash----');

  return hexHash;
}