import React, { useEffect } from 'react';
import home_work from '../../../assets/images/home-work/home-work.svg';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { scrollToTop } from '../Shared/ScrollOnTop';

const HomeWork = () => {

  document.title = "Home Work";

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="page-head">
        <div className="container">
          <h3>Home Work</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Home Work</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="simple-section" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img className="img-fluid" src={home_work} alt="" />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-9 offset-md-2">
                  <div className="home-work" data-aos="fade-right" data-aos-delay="200">
                    <h2>5th to 8th</h2>
                    <h5>All Subjects</h5>
                    <Link onClick={scrollToTop} target='_blank' to="https://www.dpskasur.edu.pk/Home_Work/Syllabus%20(5th%20-8th)%2020222-23.pdf" className="button-icon button-secondary w-100 mt-md-3">
                      Download Syllabus
                    </Link>
                  </div>
                </div>
                <div className="col-md-9 offset-md-2 mt-4">
                  <div className="home-work" data-aos="fade-right" data-aos-delay="400">
                    <h2>1st to 4th</h2>
                    <h5>All Subjects</h5>
                    <Link onClick={scrollToTop} target='_blank' to="https://www.dpskasur.edu.pk/Home_Work/Syllabus%20Junior%20Wing(1%20-%204th)%202022-23.pdf" className="button-icon button-secondary w-100 mt-md-3">
                      Download Syllabus
                    </Link>
                  </div>
                </div>
                <div className="col-md-9 offset-md-2 mt-4">
                  <div className="home-work" data-aos="fade-right" data-aos-delay="600">
                    <h2>Play Group Nursery &amp; Prep</h2>
                    <h5>All Subjects</h5>
                    <Link onClick={scrollToTop} target='_blank' to="https://www.dpskasur.edu.pk/Home_Work/Syllabus%20PG%20-%2029dPrep%2020322-23." className="button-icon button-secondary w-100 mt-md-3">
                      Download Syllabus
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeWork;
