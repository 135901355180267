import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import admission from '../../../assets/images/feestructure/admission.png';
import registration from '../../../assets/images/feestructure/registration.png';
import security from '../../../assets/images/feestructure/security.png';
import printing from '../../../assets/images/feestructure/printing.png';
import development from '../../../assets/images/feestructure/development.png';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

const FeeStructure = () => {

    document.title = "Fee Structure";

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>FEE STRUCTURE 2024-25</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link onClick={scrollToTop} to="/Home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                FEE STRUCTURE
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title mb-md-5" data-aos="fade-up" data-aos-delay="100">
                            <h3>At The Time Of Admission</h3>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="fee-card">
                                <div className="fee">
                                    <div className="mt-md-3">
                                        <h4>Registration Fee</h4>
                                        <h2>Rs 300</h2>
                                    </div>
                                    <div className="pic">
                                        <img className="img-fluid" src={admission} alt="Admission" />
                                    </div>
                                </div>
                                <div className="icon">
                                    <p>Play-Group to F.Sc</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                            <div className="fee-card">
                                <div className="fee">
                                    <div className="mt-md-3">
                                        <h4>Admission Fee</h4>
                                        <h2>Rs 8500</h2>
                                    </div>
                                    <div className="pic">
                                        <img className="img-fluid" src={registration} alt="Registration" />
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ps-4"></i> <p>Play-Group to F.Sc</p>
                                </div>
                                <div className="icon">
                                    <i className="ri-close-fill red"></i> <p>(Non-Refundable)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="fee-card">
                                <div className="fee">
                                    <div className="mt-md-3">
                                        <h4>Security Fee</h4>
                                        <h2>Rs 3000</h2>
                                    </div>
                                    <div className="pic">
                                        <img className="img-fluid" src={security} alt="Security" />
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ri-check-fill green"></i> <p>(Refundable)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="fee-card">
                                <div className="fee">
                                    <div className="mt-md-3">
                                        <h4>Stationery/Printing Charges</h4>
                                        <h2>Rs 1800</h2>
                                    </div>
                                    <div className="pic">
                                        <img className="img-fluid" src={printing} alt="Printing" />
                                    </div>
                                </div>
                                <div className="icon">
                                    <p>(Per-Annum)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="fee-card">
                                <div className="fee">
                                    <div className="mt-md-3">
                                        <h4>Development fund</h4>
                                        <h2>Rs 8000</h2>
                                    </div>
                                    <div className="pic">
                                        <img className="img-fluid" src={development} alt="Development" />
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ri-close-fill red"></i> <p>(Non-Refundable)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title mb-md-5" data-aos="fade-up" data-aos-delay="100">
                            <h3>Monthly Tuition Fee</h3>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="fee-card fee">
                                <div className="fee">
                                    <div>
                                        <h4>Play Group</h4>
                                        <h2>Rs 4000</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                            <div className="fee-card fee">
                                <div className="fee">
                                    <div>
                                        <h4>Nursery to Class 5th</h4>
                                        <h2>Rs 3550</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="fee-card fee">
                                <div className="fee">
                                    <div>
                                        <h4>6th to 8th &amp; Hifz-ul-Quran</h4>
                                        <h2>Rs 4000</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="fee-card fee">
                                <div className="fee">
                                    <div>
                                        <h4>9th &amp; 10th</h4>
                                        <h2>Rs 4300</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="fee-card fee">
                                <div className="fee">
                                    <div>
                                        <h4>1st Year &amp; 2nd Year</h4>
                                        <h2>Rs 4620</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeeStructure;
