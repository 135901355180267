import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Helmet } from 'react-helmet';
import { scrollToTop } from '../Shared/ScrollOnTop';
import { RenderHTMLStringWithoutDiv } from "../General/Utility";

const NewsDetail = () => {

    let { id } = useParams()
    const [AccessKey, setAccessKey] = useState("257d7805-abc9-44ad-9530-a8db18535699");
    const [UserID, setUserID] = useState("1");
    const [MemberType, setMemberType] = useState("Website");

    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ContentPage, setContentPage] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [Title, setTitle] = useState(0);
    const [listOfNews, setlistOfNews] = useState([]);
    const [News, setNews] = useState({});


    useEffect(() => {
        document.title = "News Details";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: id,
            MemberType: MemberType,
        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'News/GetNewsByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log("our data", response.data);
                if (response.data.status_code == 1) {
                    setNews(response.data.News);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, [id]);

    useEffect(() => {

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            Title: Title,
            MemberType: MemberType,
            pageSize: pageSize,
        };
        //console.log(data)

        var api_config = {
            method: "post",
            url: config.base_url + "News/GetAllNews",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setlistOfNews(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, []);

    const renderNews = () => {
        if (listOfNews != null && listOfNews.length > 0) {
            let count = 1;
            return listOfNews.slice(0, 3).map((item, index) => {
                return (
                    <li
                        key={index}
                        data-aos="fade-up"
                        data-aos-duration="500"
                    >
                        <Link onClick={scrollToTop} to={"/Home/NewsDetail/" + item.ID} className="sch-card">
                            <span><i className="ri-megaphone-fill"></i></span>
                            <div className="text">
                                <h1>{item.Title}</h1>
                                <p className="">
                                    {item.Short_Description}
                                </p>
                            </div>
                            <div className="read-more">
                                <div className="date">
                                    <i className="ri-calendar-line"></i>{' '}
                                    <span className="">{moment(item.Dated).format('DD-MMM-YYYY')}</span>
                                </div>
                                <div className="">
                                    <Link onClick={scrollToTop} className="" to={"/Home/NewsDetail/" + item.ID}>
                                        Read More <i className="ri-arrow-right-line"></i>
                                    </Link>
                                </div>
                            </div>
                        </Link>
                    </li>

                );
            });
        } else {
            return (
                <tr>
                    <td colspan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: '15px', color: 'rgba(0,0,0,0.6)' }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };



    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>NEWS DETAIL</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">NEWS DETAIL</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section pb-4">
                <div className="container">
                    <div className="row">
                        {loading ? (
                            <div className="card">
                                <div className="card-body p-4">
                                    <Spinner animation="border" role="status"></Spinner>
                                </div>
                            </div>
                        ) : (
                            <>

                                <div className="col-md-12" data-aos="fade-up" data-aos-duration="500">
                                    <div className="news-detail-card">
                                        <h1>{News.Title}</h1>
                                        <Helmet>
                                            <meta name="title" content={News.Meta_Title} />
                                            <meta name="description" content={News.Short_Description} />
                                        </Helmet>
                                        <p>{News.Short_Description}</p>
                                        <p>{RenderHTMLStringWithoutDiv(News.Detail)}</p>
                                        <div className="read-more">
                                            <div className="date">
                                                <i className="ri-calendar-line"></i>
                                                <span>
                                                    {moment(News.Dated).format('DD-MMM-YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>

            <section className="simple-section pt-3">
                <div className="container">
                    <div className="row align-items-center mb-md-5">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h3>School News</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="school text-end">
                                <Link className="button-icon button-white" to="/Home/NewsList">
                                    View All <span><i className="ri-arrow-right-s-line"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <div className="card">
                            <div className="card-body p-4">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                        </div>
                    ) : (
                        <>
                            <ul className="responsive news-responsive">
                                {renderNews()}
                            </ul>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default NewsDetail