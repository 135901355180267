import imgSMS from "../../assets/images/icons/sms.svg";
import Admin from "../../assets/images/icons/Admin.svg";
import Event from "../../assets/images/icons/Event.svg";
import News from "../../assets/images/icons/News.svg";
import Gallery from "../../assets/images/icons/Gallery.svg";
import Message from "../../assets/images/icons/Message.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

function Dashboard() {

    const AccessKey = localStorage.getItem("AccessKey");
    const navigate =useNavigate();
    if (!AccessKey) {
        navigate('/web-admin/login');
    }



    return (
        <div className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <ul className="dashboard-buttons">

                            <li>
                                <Link to="/web-admin/message">
                                    <img src={Message} alt="Banner" />
                                    <span>Message</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/web-admin/administration">
                                    <img src={Admin} alt="Banner" />
                                    <span>Administration</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/web-admin/bog">
                                    <img src={Admin} alt="Banner" />
                                    <span>BOG</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/web-admin/events">
                                    <img src={Event} alt="Event" />
                                    <span>Events</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/web-admin/news">
                                    <img src={News} alt="News" />
                                    <span>News</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/web-admin/photos">
                                    <img src={Gallery} alt="Gallery" />
                                    <span>Photo Gallery</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/web-admin/videos">
                                    <img src={Video} alt="Video" />
                                    <span>Videos</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/web-admin/content">
                                    <img src={imgSMS} alt="imgSMS" />
                                    <span>Contents</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
