import React from 'react'
import cricle_border from '../../../assets/images/Syllabus/cricle-border.svg'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../Shared/ScrollOnTop';

const Syllabus = () => {

    document.title = "Syllabus";

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>SYLLABUS</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">SYLLABUS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <ul className="syllabus">
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>10th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' toload="" href="https://www.dpskasur.edu.pk/Syllabus/Class-10th.pdf" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>9th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-9th.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>8th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-8th.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>7th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-7th.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>6th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-6th.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>5th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-5th.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>4th</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-4th.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>3rd</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-3rd.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>2nd</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-2nd.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>1st</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-1st.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>Hifz</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Class-Hifz.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>prep</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/prep-class.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>

                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3>Nursery</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/Nursery-class.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>
                        <li>
                            <div className="circle">
                                <div className="content">
                                    <h3 style={{ fontSize: "30px" }}>Play Group</h3>
                                    <span className="class">Class</span>
                                    <Link target='_blank' to="https://www.dpskasur.edu.pk/Syllabus/play-group-class.pdf" download="" className="button button-primary button-icon">
                                        Download<span className="ri-download-line"></span>
                                    </Link>
                                </div>
                                <img src={cricle_border} alt="" />
                            </div>
                        </li>

                    </ul>

                </div>
            </section>
        </>
    )
}

export default Syllabus