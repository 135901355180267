import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import Pagination from "../Shared/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';

const NewsList = () => {

    document.title = "News List";

    const [AccessKey, setAccessKey] = useState("239f5be8-5389-44ea-8935-b5fe638339da");
    const [UserID, setUserID] = useState("1");
    const [MemberType, setMemberType] = useState("Website");

    const [showFilters, setShowFilters] = useState(true);

    const [loading, setLoading] = useState(false);
    const [listOfNews, setNews] = useState([]);
    const [validated, setValidated] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [totalRecords, setTotalRecords] = useState(0);


    const [Title, setTitle] = useState(0);

    const [Month, setMonth] = useState(moment().format("M"));
    const [Year, setYear] = useState(moment().format("YYYY"));

    useEffect(() => {
        document.title = "News Page";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            Title: Title,
            MemberType: MemberType,
            pageSize: pageSize,
        };
        //console.log(data)

        var api_config = {
            method: "post",
            url: config.base_url + "News/GetAllNews",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setNews(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, Title]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderNews = () => {
        if (listOfNews != null && listOfNews.length > 0) {
            let count = 1;
            return listOfNews.map((item, index) => {
                return (
                    <li
                        key={index}
                        data-aos="fade-up"
                        data-aos-duration="500"
                    >
                        <Link onClick={scrollToTop} to={"/Home/NewsDetail/" + item.ID} className="sch-card">
                            <span><i class="ri-megaphone-fill"></i></span>
                            <div className="text">
                                <h1>
                                    {item.Title.length > 55
                                        ? `${item.Title.substring(0, 55)}...`
                                        : item.Title}
                                </h1>
                                <p className="">
                                    {item.Short_Description.length > 180
                                        ? `${item.Short_Description.substring(0, 180)}...`
                                        : item.Short_Description}
                                </p>
                            </div>
                            <div className="read-more">
                                <div className="date">
                                    <i className="ri-calendar-line"></i>{' '}
                                    <span className="">{moment(item.Dated).format('DD-MMM-YYYY')}</span>
                                </div>
                                <div className="">
                                    <Link onClick={scrollToTop} to={"/Home/NewsDetail/" + item.ID}>Read More <i class="ri-arrow-right-line"></i></Link>
                                </div>
                            </div>
                        </Link>
                    </li>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: '15px', color: 'rgba(0,0,0,0.6)' }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };


    return (
        <>
        <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <h3>NEWS LIST</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">NEWS LIST</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center mb-md-5">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h3>School News</h3>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <tr>
                            <td className="text-center" colspan="9">
                                <Spinner animation="border" role="status"></Spinner>
                            </td>
                        </tr>
                    ) : (
                        <>
                            <div className="responsive">
                                {renderNews()}
                            </div>
                        </>
                    )}
                    <div className="mt-5">
                        {totalRecords > 10 && (
                            <div className="pagination-wrap">
                                <div className="row align-items-center">
                                    <div className="col-md-2 form_default">
                                        <div className="d-flex align-items-center">
                                            <label className="me-2" style={{ fontSize: "15px" }}>
                                                Page Size
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-select"
                                                    style={{ width: "100px" }}
                                                    onChange={(e) => {
                                                        setPageNo(1);
                                                        setPageSize(parseInt(e.target.value));
                                                    }}
                                                >
                                                    <option value="10" selected>
                                                        10
                                                    </option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <Pagination
                                        pageNo={pageNo}
                                        pageSize={pageSize}
                                        totalRecords={totalRecords}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </section>
        </>
    )
}

export default NewsList