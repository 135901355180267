import React, { useEffect } from 'react';
import play_ground_1 from '../../../assets/images/play-ground/play-ground-1.JPG';
import play_ground_2 from '../../../assets/images/play-ground/play-ground-2.JPG';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Playgrounds = () => {

  document.title = "Playgrounds";

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="page-head">
        <div className="container">
          <h3>PLAY GROUNDS</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link onClick={scrollToTop} to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                PLAY GROUNDS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="simple-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12" data-aos="fade-right">
              <div className="uniform">
                <div
                  id="play-ground"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#play-ground"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#play-ground"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="img-fluid rounded-2"
                        src={play_ground_1}
                        alt=""
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="img-fluid rounded-2"
                        src={play_ground_2}
                        alt=""
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#play-ground"
                    data-bs-slide="prev"
                  >
                    <span
                      className="ri-arrow-left-s-line prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#play-ground"
                    data-bs-slide="next"
                  >
                    <span
                      className="ri-arrow-right-s-line next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12" data-aos="fade-left">
              <div className="">
                <b>
                  The school playground provides a safe outdoors environment
                  within the school that stimulates children to use their
                  creative energy in healthy interactions with one another.
                </b>
                <p>
                  Children sit at their classroom desk for many hours each day.
                  They are given breaks in between where they go outdoors to the
                  playground. The key to these playgrounds is choice: use the
                  play equipment, run on the field, jump rope, play basketball,
                  or create some new game to challenge friends. A good
                  playground will be large with many different surfaces
                  (blacktop, grass, sand, etc.) to stimulate choice and leave
                  options open.
                  <br />
                  <br />
                  The playgrounds must be spacious and outdoors, but they must
                  also be secluded so that the children (and their parents) feel
                  safe and do not have to consider the outside world. A good
                  playground will allow for anyone on the playground (including
                  the teacher) to see all that is going on, but will not have
                  any connections or visibility to the street or any location
                  off school grounds. At the same time, it should seem open and
                  spacious. Thus, some high walls are necessary, but using the
                  school buildings as boundaries as well can preserve the open
                  feeling of the playground.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Playgrounds;
