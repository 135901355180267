import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import config from "../../../Config";
import { Editor } from "@tinymce/tinymce-react";


function EditNews(props) {

    const AccessKey = localStorage.getItem("AccessKey");
   const UserID = localStorage.getItem("ID")
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ContentPage, setContentPage] = useState({});

    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState("");
    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
    };


    // photo
    const [isUpdated, setIsUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [imgData, setImageData] = useState("");

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    };

    const [News, setNews] = useState({});
    let params = useParams();
    const [NewsID, setNewsID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Edit News";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: NewsID
        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'News/GetNewsByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setNews(response.data.News);

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/web-admin/login");
                }
                else {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    useEffect(() => {
        if (image && cropper) {
            cropper.replace(image);
        }
    }, [image, cropper]);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: NewsID,
                Title: event.target.txtTitle.value,
                Dated: event.target.txtDated.value,
                Source: event.target.txtSource.value,
                Meta_Title: event.target.txtMeta_Title.value,
                Meta_Description: event.target.txtMeta_Description.value,
                Meta_Keywords: event.target.txtMeta_Keywords.value,
                Short_Description: event.target.txtShort_Description.value,
                Detail: editorContent,
                News_Image: "",
                Is_Approved: true,
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'News/UpdateNews',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        navigate(`/web-admin/news`);
                    }
                    else if (response.data.status_message === "Invalid Access Key.") {
                        navigate("/web-admin/login");
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };
    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);

            var cleanerBase64 = cropData1.substring(22);
            setCropData(cleanerBase64);
            setImageData(cropData1);
        }

        handleCloseModal();
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button
                            className="button button-outline-primary me-2"
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                        <button className="button button-primary" onClick={getCropData}>
                            Save Photo
                        </button>
                    </div>
                </>
            );
        } else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button
                            className="button button-outline-primary me-2"
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                    </div>
                </>
            );
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdated ? (
                        <>
                            <div className="text-center">
                                <p>Photo Updated Successfully</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="upload-photo-wrap">
                                {imageSelected ? (
                                    <Cropper
                                        zoomTo={0.5}
                                        aspectRatio={16 / 9}
                                        preview=".img-preview"
                                        src={image}
                                        dragMode="move"
                                        viewMode={2}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        guides={true}
                                    />
                                ) : (
                                    <div className="choose-photo">
                                        <label htmlFor="ChoosePhoto">
                                            <i className="ri-upload-cloud-line"></i>
                                            <span>Browse Photo</span>
                                            <input
                                                id="ChoosePhoto"
                                                accept="image/jpeg,image/png"
                                                type="file"
                                                onChange={onChange}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>

                {renderButtons()}
            </Modal>
            <div className="admin-page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/web-admin/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit News</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit News</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/web-admin/news" className="button button-white button-icon">
                                    Manage News
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content mb-5">
                <div className="container">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row align-items-center g-2">
                            <div className="col-md-12">
                                <div className="card" style={{ minHeight: "287px" }}>
                                    <div className="card-body">
                                        <div className="row gy-3">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Title:</label>
                                                    <input defaultValue={News.Title} type="text" className="form-control" required name="txtTitle" />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label className="">Source:</label>
                                                    <input defaultValue={News.Source} type="text" className="form-control" required name="txtSource" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="">Dated:</label>
                                                    <input defaultValue={moment(News.Dated).format("YYYY-MM-DD")} type="date" className="form-control" required name="txtDated" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="">Short Description:</label>
                                                    <textarea rows={3} type="text" defaultValue={News.Short_Description} className="form-control" required name="txtShort_Description" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Select Photo:</label>
                                        </div>
                                        <img

                                            className="mb-3"
                                            src={imgData}
                                            style={{ width: "100%", height: "150px" }}
                                        />

                                        <button
                                            type="button"
                                            onClick={handleShowModal}
                                            className="button w-100 button-small"
                                        >
                                            Choose Photo
                                        </button>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label className="">Page Details :</label>
                                            <Editor
                                                apiKey={config.Editor_Apikey}
                                                init={{
                                                    height: 400,
                                                }}
                                                initialValue={News.Detail}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                onEditorChange={handleEditorChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row gy-3">
                                            <div className="col-md-7">
                                                <div className="form-group">
                                                    <label className="">SEO Title:</label>
                                                    <input defaultValue={News.Meta_Title} type="text" className="form-control" required name="txtMeta_Title" />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label className="">SEO Keywords:</label>
                                                    <input defaultValue={News.Meta_Keywords} type="text" className="form-control" required name="txtMeta_Keywords" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="">SEO Description:</label>
                                                    <textarea defaultValue={News.Meta_Description} rows={3} className="form-control" required name="txtMeta_Description" />
                                                </div>
                                            </div>
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    required
                                                    label="Agree to terms and conditions"
                                                    feedback="You must agree before submitting."
                                                    feedbackType="invalid"
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 offset-md-10">
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        value="Save"
                                                        className="button button-primary w-100"
                                                        disabled={loading}
                                                    >
                                                        SAVE {loading ? '...' : ''}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default EditNews;