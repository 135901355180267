import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import img1 from '../../../assets/images/school-uniform/1.jpg'
// import img2 from '../../../assets/images/school-uniform/2.jpg'
// import img3 from '../../../assets/images/school-uniform/3.jpg'
// import img4 from '../../../assets/images/school-uniform/4.jpg'
// import img5 from '../../../assets/images/school-uniform/5.jpg'
// import img6 from '../../../assets/images/school-uniform/6.jpg'
// import img7 from '../../../assets/images/school-uniform/7.jpg'
// import img8 from '../../../assets/images/school-uniform/8.jpg'
// import img9 from '../../../assets/images/school-uniform/9.jpg'
// import img10 from '../../../assets/images/school-uniform/10.jpg'
// import img11 from '../../../assets/images/school-uniform/11.jpg'
// import img12 from '../../../assets/images/school-uniform/12.jpg'
// import img13 from '../../../assets/images/school-uniform/13.jpg'
import rules from '../../../assets/images/school-uniform/rules.svg'
import { scrollToTop } from '../Shared/ScrollOnTop';

const SchoolUniform = () => {

    document.title = "School Uniform";

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            easing: 'ease-in-out',
        });
    }, []);
    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>School Uniform</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">School Uniform</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center gy-5 gx-md-5">
                        <div className="col-md-12">
                            <p className="">
                                <b className="">
                                    All student are expected to be smartly turned out. Only the appropriate prescribed school &amp; Collage uniform will be worn inside D.P.S &amp; Collage at all times.The same rules apply when student go on visits and represent the in any event.
                                </b>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section  bg-white">
                <div className="container">
                    <div className="row align-items-center gy-5 gx-md-5">
                        <div className="col-md-12">
                            <h1 className="text-center">
                                <b>
                                    PREP WING
                                </b>
                            </h1>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <h4>
                                <b>
                                    Play Group Uniform <br /> (Girls)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White blouse full sleeves with checked bow tie</li>
                                    <li>Multi checked tunic having pocket at right side with school monogram</li>
                                    <li>White tights, white socks, black shoes (Bata Style)</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    (Boys)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White shirt with school monogram at pocket</li>
                                    <li>Multi stripped neck tie, grey trousers, white socks, black shoes (Oxford Style) with laces</li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="uniform">
                                <div id="PREP-WING" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#PREP-WING" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#PREP-WING" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="img-fluid" src={img1} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img2} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#PREP-WING" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon prev-icon" aria-hidden="true"></span>
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#PREP-WING" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-8 col-sm-12">
                            <h4>
                                <b>
                                    NURSERY + PREP UNIFORM <br /> (Girls Summer)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White blouse full sleeves with bow tie</li>
                                    <li>White &amp; blue checked tunic having pocket at right side with school monogram</li>
                                    <li>White tights, white socks, black shoes (Bata Style)</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    (Boys Summer)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White shirt with school monogram on pocket</li>
                                    <li>Grey trousers(full elastic)</li>
                                    <li>Black shoes (OXford style) with laces, white socks ,Navy blue yellow stripped neck tie.</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    WINTER UNIFORM
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>Same as Summer with blue blazer, Grey sweeters is allowed underneath blue blazer</li>
                                    <li>inner should be white, blue cap.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="uniform">
                                <div id="NURSERY-PREP" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#NURSERY-PREP" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#NURSERY-PREP" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#NURSERY-PREP" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="img-fluid" src={img8} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img11} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img9} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#NURSERY-PREP" data-bs-slide="prev">
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#NURSERY-PREP" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center gy-5 gx-md-5">
                        <div className="col-md-12">
                            <h1 className="text-center">
                                <b>
                                    JUNIOR WING (Class 1 - 4)
                                </b>
                            </h1>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <h4>
                                <b>
                                    (Girls)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White A-line shirt with blue checked collar and blue checked strip having three white buttons</li>
                                    <li>White strap at the back and front pocket with school monogram</li>
                                    <li>White shlwar &amp; grey shocks, black shoes(Bata Style) for the girls of class 3 &amp; 4 checked shashes are compulsory</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    (Boys)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White shirt with school monogram and grey trousers</li>
                                    <li>Black shoes (Oxford Style) with laces and grey socks, nvy blue yellow stripped neck tie</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    WINTER UNIFORM
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>Same as Summer with blue blazer, Grey sweeters is allowed underneath blue blazer</li>
                                    <li>inner should be white, blue cap.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="uniform">
                                <div id="prep-wing" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#prep-wing" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#prep-wing" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="img-fluid" src={img3} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img4} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#prep-wing" data-bs-slide="prev">
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#prep-wing" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row align-items-center gy-5">
                        <div className="col-md-12">
                            <h1 className="text-center">
                                <b>
                                    GIRLS WING (Class 5 - 10)
                                </b>
                            </h1>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <h4>
                                <b>
                                    Class 5-8
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White shalwar qamiz with checked collar &amp; cuffs (half sleeves are not allowed)</li>
                                    <li>Navy Blue blazer/ V-shaped pullover (Grey)</li>
                                    <li>White scarf from class 5 to 8 Duppatta white with 1 1/2 inch checked border (class 7 &amp; 8)</li>
                                    <li>Checked sash for class 5 &amp; 6.</li>
                                    <li>Black school shoes with buckle (Bata Style), grey sockes, school bag should be black</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    Class 9th &amp; 10th
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White shalwar kameez with collar &amp; cuffs men style, (half sleeves are not allowed)</li>
                                    <li>Blue Coat, white scarf, black shoes with buckle (Bata Style), socks grey, school bag should be black</li>
                                    <li>Peach Duppatta for class 9th </li>
                                    <li>Purple Duppatta for class 10th</li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="uniform">
                                <div id="girl-wing" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#girl-wing" data-bs-slide-to="0" className="" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#girl-wing" data-bs-slide-to="1" aria-label="Slide 2" className=""></button>
                                        <button type="button" data-bs-target="#girl-wing" data-bs-slide-to="2" aria-label="Slide 3" className="active" aria-current="true"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img7} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img10} alt="" />
                                        </div>
                                        <div className="carousel-item active">
                                            <img className="img-fluid" src={img12} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#girl-wing" data-bs-slide="prev">
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#girl-wing" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center gy-5">
                        <div className="col-md-12">
                            <h1 className="text-center">
                                <b>
                                    Boys WING (Class 5 - 10)
                                </b>
                            </h1>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className="promotion-policy">
                                <ul>
                                    <li>White shirt with school monogram and grey trousers</li>
                                    <li>Black shoes (Oxford Style) with laces and grey socks, nvy blue yellow stripped neck tie</li>
                                    <li>V-Shaped navy blue blazer is compulsory for all.</li>
                                    <li>Only grey sweater is allowed underneath blazer</li>
                                    <li>Blazer navy blue, pullover v-shaped grey, </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="uniform">
                                <div id="boy-wing" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#boy-wing" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#boy-wing" data-bs-slide-to="1" aria-label="Slide 2"></button>

                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="img-fluid" src={img13} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img13} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#boy-wing" data-bs-slide="prev">
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#boy-wing" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row align-items-center gy-5">
                        <div className="col-md-12 col-sm-12">
                            <h1 className="text-center">
                                <b>
                                    COLLAGE UNIFORM
                                </b>
                            </h1>
                        </div>
                        <div className="col-md-8">
                            <h4>
                                <b>
                                    Girls (XI - XII)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>Pista green, white shalwar,light and dark green striped dupatta.</li>
                                    <li>Black shoes and socks.</li>
                                    <li>Dark green blazer in winter.</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    Boys (XI - XII)
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>White ,black and peach colored stripped shirt</li>
                                    <li>Grey trousers and socks, black school shoes, navy blue white striped (necktie)</li>
                                    <li>grey pullover.</li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="uniform">
                                <div id="COLLAGE-UNIFORM" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#COLLAGE-UNIFORM" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#COLLAGE-UNIFORM" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="img-fluid" src={img5} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid" src={img6} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#COLLAGE-UNIFORM" data-bs-slide="prev">
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#COLLAGE-UNIFORM" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center gy-5">
                        <div className="col-md-6 col-sm-12">
                            <h4>
                                <b>
                                    UNIFORM RULES
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>The students will be in prescribed uniform when they are in school</li>
                                    <li>During academic days, students will not be allowed to enter the school premises without uniform.</li>
                                    <li>Uniform must be clean and tidy.</li>
                                </ul>
                            </div>
                            <h4>
                                <b>
                                    CLEANLINESS
                                </b>
                            </h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>The students will come in the school with proper haircut and dressing.</li>
                                    <li>Teeth must be brushed and cleaned everyday.</li>
                                    <li>Students will have to pare and clean their nails.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img className="img-fluid" src={rules} alt=""/>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default SchoolUniform