import React from 'react'

const Videos = () => {
    return (
        <>
            <h1>Videos</h1>
        </>
    )
}

export default Videos
