import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import config from "../../../Config";
import { Editor } from "@tinymce/tinymce-react";


function AddNews(props) {

    const AccessKey = localStorage.getItem("AccessKey");
   const UserID = localStorage.getItem("ID")
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    document.title = 'Add News';


    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState("");
    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
    };

    let navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Dated: event.target.Dated.value,
                Title: event.target.Title.value,
                Source: event.target.Source.value,
                Meta_Title: event.target.Meta_Title.value,
                Meta_Description: event.target.Meta_Description.value,
                Meta_Keywords: event.target.Meta_Keywords.value,
                Short_Description: event.target.Short_Description.value,
                Detail: editorContent,
                News_Image: "",
                Is_Approved: true,
            };

            //console.log(data);

            var api_config = {
                method: "post",
                url: config.base_url + "/News/AddNews",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate(`/web-admin/news`);
                    } else if (response.data.status_message === "Invalid Access Key.") {
                        navigate("/web-admin/login");
                    } else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }

                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error("Network Error..", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }

        setValidated(true);
    };



    return (
        <>
            <ToastContainer />

            <div className="admin-page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/web-admin/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Add News
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">Add News</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link
                                    to="/web-admin/news"
                                    className="button button-white button-icon"
                                >
                                    Manage News
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content mb-5">
                <div className="container">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row align-items-center g-2">
                            <div className="col-md-12">
                                <div className="card" style={{ minHeight: "287px" }}>
                                    <div className="card-body">
                                        <div className="row gy-3">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Title:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="Title"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label className="">
                                                        Source:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="Source"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="">
                                                        Dated:
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        required
                                                        name="Dated"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="">
                                                        Short Description:
                                                    </label>
                                                    <textarea
                                                        rows={3}
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="Short_Description"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Select Photo:</label>
                                        </div>
                                        <img
                                            className="mb-3"
                                            src={imgData}
                                            style={{ width: "100%", height: "150px" }}
                                        />
                                        <button
                                            type="button"
                                            onClick={handleShowModal}
                                            className="button w-100 button-small"
                                        >
                                            Choose Photo
                                        </button>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label className="">Page Details :</label>
                                            <Editor
                                                apiKey={config.Editor_Apikey}
                                                init={{
                                                    height: 400,
                                                }}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                onEditorChange={handleEditorChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="form-group mb-3">
                                                    <label className="">
                                                        SEO Title:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="Meta_Title"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group mb-3">
                                                    <label className="">
                                                        SEO Keywords:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="Meta_Keywords"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group mb-3">
                                                    <label className="">
                                                        SEO Description:
                                                    </label>
                                                    <textarea
                                                        rows={3}
                                                        className="form-control"
                                                        required
                                                        name="Meta_Description"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 offset-md-10">
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        value="Save"
                                                        className="button button-primary w-100"
                                                        disabled={loading}
                                                    >
                                                        SAVE {loading ? '...' : ''}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default AddNews;
