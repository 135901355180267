import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Pagination from "../../General/Pagination";
import { Form, PageItem } from "react-bootstrap";


function Events() {
  const AccessKey = localStorage.getItem("AccessKey");
 const UserID = localStorage.getItem("ID")
  const MemberType = localStorage.getItem("MemberType");
  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [Title, setTitle] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Manage Events";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Title: Title,
    };
    //console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "Events/GetAllEvents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {

          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        }
        else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/web-admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Title]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setTitle(Title)
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td className="text-nowrap">{moment(item.Dated).format('DD-MMM-YYYY')}</td>
            <td>{item.Title}</td>
            <td>{item.Source}</td>
            <td>{item.Views}</td>

            <td>
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to={"/web-admin/edit-events/" + item.ID}
                    className="primary"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => DeleteEvent(item.ID)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const DeleteEvent = (ID) => {
    if (window.confirm('Are you sure you want to proceed with this process?')) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Events/DeleteEvent",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
          }

        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="admin-page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/web-admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Events
                  </li>
                </ol>
              </nav>
              <div className="page-title">Manage Events</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/web-admin/add-events"
                  className="button button-white button-icon"
                >
                  Add Events
                  <i className="ri-add-line"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <div className="container">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group">
                        <label for="" className="">
                          Title:
                        </label>
                        <input type="text" onChange={(e) => setTitle(e.target.value)} className="form-control" />
                      </div>
                    </div>

                    <div className="col-md-2 text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="w-100 button button-primary mt-4"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="page-content mb-5">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme table-hover">
                <thead>
                  <tr>
                    <th className="text-end" colSpan={6}>
                      {totalRecords} Records Found!
                    </th>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <th style={{ width: "115px" }}>Dated</th>
                    <th>Title</th>
                    <th>Source</th>
                    <th>Views</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="9">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Events;
