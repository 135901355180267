import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import computer_lab from '../../../assets/images/computer-lab/computer-lab.jpg';
import { scrollToTop } from '../Shared/ScrollOnTop';

const ComputerLab = () => {

    document.title = "Computer Lab";

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>COMPUTER LAB</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link onClick={scrollToTop} to="/Home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                COMPUTER LAB
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6" data-aos="fade-up">
                            <div className="">
                                <p>
                                    Keeping the significance of computer knowledge, the school offers well-equipped computer labs in both wings (boys & girls) under the supervision of highly motivated and well-trained computer instructors.
                                </p>
                                <p>
                                    As the I.T revolution accelerates, computer system plays rapidly growing role in our daily lives. When we fly, the aircraft is controlled by computers. Our electricity, telephone network, mobile phone rely on computers for their operation.
                                </p>
                                <p>
                                    Therefore, it is essential for successful commercial experts to have a firm base of knowledge and practical experience of computing in their educational careers. To this end, computer is being taught as a subject at Intermediates and Bachelors levels according to the Board. It keeps the students abreast of the latest computing developments, enabling them to embrace the new challenges of twenty-first century.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="fade-up">
                            <div>
                                <img className="img-fluid rounded-2" src={computer_lab} alt="" />
                            </div>
                        </div>
                        <div className="col-md-12 mt-3" data-aos="fade-up">
                            <p>
                                <b>DPS Kasur has latest and high-speed workstations,</b> with wireless networking where students can learn computing in an ideal environment. Computing facilities are constantly reviewed and updated to meet teaching requirements and objectives.
                            </p>
                            <p>
                                We believe that the new generation must be well-equipped and exposed to tomorrow's technology. With this vision, DPS Kasur has equipped its IT lab with high-performance Pentium IV computers. This is in addition to the already existing IT infrastructure of DPS Kasur, which consists of IT labs with a number of high-performance machines for undergraduate projects and research activities. The laboratory is being used for the projects and assignments related to new emerging fields such as multimedia applications. The aim of this laboratory is to provide required computing and simulation facilities to the undergraduate students in order to meet their requirements of computer engineering profession. The main emphasis is on learning object-oriented programming, computer graphics, software engineering, database applications, and implementing different data structures.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ComputerLab;
