import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';
import axios from 'axios';
import config from '../../../Config';
import { Spinner } from 'react-bootstrap';
import user from '../../../assets/images/administration/user.png'
const BoardOfGovernors = () => {

    const AccessKey = localStorage.getItem("239f5be8-5389-44ea-8935-b5fe638339da");
    const UserID = localStorage.getItem("1");
    const MemberType = localStorage.getItem("Website");
   

    document.title = "BoardOfGovernors";

    useEffect(() => {
        AOS.init();
    }, []);
    const [listOfPage, LedgerPage] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 2000,
        };
        //console.log(data)

        var api_config = {
            method: "post",
            url: config.base_url + "BoardMember/GetAllBoardMemberWithoutKey",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                LedgerPage(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, []);



    const renderPage = () => {
        if (listOfPage != null && listOfPage.length > 0) {

            return listOfPage.map((item, index) => {
                return (
                    <>
                        <li data-aos="fade-up">
                            <div className="bog-member">
                                <div className="img-wrap">
                                    <img className="img-fluid"
                                        src={`${config.images + 'Images/BoardMembers/' + item.Photo}`}
                                        onError={(e) => e.target.src = user}
                                        alt={item.Name} />
                                </div>

                                <h3>{item.Name}</h3>
                                <p>{item.Designation}</p>
                                <span>{item.Designation1}</span>
                            </div>
                        </li>
                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>Board Of Governors</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link onClick={scrollToTop} to="/Home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Board Of Governors
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <ul className="responsive bog-responsive">
                        {
                            loading ?
                                <Spinner></Spinner>
                                :
                                renderPage()
                        }
                    </ul>
                </div>
            </section>
        </>
    )
}

export default BoardOfGovernors