import React, { useState, useEffect } from "react";
import { NavLink, Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import imgLogo from "../../assets/images/logo/Logo.png"
import imgUser from "../../assets/images/user.png"
import Offcanvas from 'react-bootstrap/Offcanvas';
import imgSMS from "../../assets/images/icons/sms.svg";
import Admin from "../../assets/images/icons/Admin.svg";
import Event from "../../assets/images/icons/Event.svg";
import News from "../../assets/images/icons/News.svg";
import Gallery from "../../assets/images/icons/Gallery.svg";
import Message from "../../assets/images/icons/Message.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import config from "../../Config";



function AdminMain(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("UserID")
    const [FullName, setFullName] = useState(localStorage.getItem("FullName"));
    const [User_Type, setUser_Type] = useState(localStorage.getItem("User_Type"));
    const [Group_Name, setGroup_Name] = useState(localStorage.getItem("Group_Name"));
    const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.clear();
        navigate("/web-admin/login");
    }

    useEffect(() => {

        if (AccessKey == null) {
            navigate("/web-admin/login");
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                User_Type: User_Type,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'WebUser/ValidateAccessKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    // console.log(response.data,'admin-main')
                    if (response.data.status_message == 'Invalid Access Key.') {
                        localStorage.clear();
                        navigate("/web-admin/login");
                    }

                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }, []);

    return (
        <>
            <header className="admin-header">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-5 col-md-2">
                            <NavLink to="/web-admin/dashboard">
                                <img src={imgLogo} className="img-fluid brand" alt="" />
                            </NavLink>
                        </div>
                        <div className="col-7 col-md-10">
                            <ul className="navbar-general">
                                <li className="admin-toggle-side-menu">
                                    <a onClick={handleShow} style={{ cursor: "pointer" }}>
                                        <i className="ri-menu-3-fill"></i>
                                    </a>
                                </li>
                                <li className="user-logged-in">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="user-dropdown text-start" id="dropdownUserLoggedIn">
                                            <div className="thumb">
                                                <img src={imgUser} />
                                            </div>
                                            <div className="info">
                                                <span className="user_name">
                                                    {FullName}
                                                </span>
                                                <span className="role">{User_Type}</span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <NavLink className="dropdown-item" to="/web-admin/change-password"><i className="ri-lock-2-line me-2"></i>Change Password</NavLink>
                                            <button onClick={handleSignOut} className="dropdown-item"><i className="ri-logout-box-line me-2"></i>Logout</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <Offcanvas className='admin-sidebar' show={show} style={{ width: "300px" }} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="offcanvas-main-menu">
                        <li>
                            <Link to="/web-admin/message">
                                <img src={Message} alt="Banner" />
                                <span>Message</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/web-admin/administration">
                                <img src={Admin} alt="Banner" />
                                <span>Administration</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/web-admin/bog">
                                <img src={Admin} alt="Banner" />
                                <span>BOG</span>
                            </Link>
                        </li>
                        <li>
                            <NavLink to="/web-admin/events">
                                <img src={Event} alt="" />
                                <span>Events</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/web-admin/news">
                                <img src={News} alt="" />
                                <span>News</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/web-admin/photos">
                                <img src={Gallery} alt="" />
                                <span>Photo Gallery</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/web-admin/videos">
                                <img src={Video} alt="" />
                                <span>Videos</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/web-admin/content">
                                <img src={imgSMS} alt="" />
                                <span>Contents</span>
                            </NavLink>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>

            <nav className="navbar navbar-expand-lg navbar-module">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav  m-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/message">Message</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/administration">Administration</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/bog">BOG</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/events">Events</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/news">News</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/photos">Photo Gallery</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/videos"> Videos</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/web-admin/content">Contents</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet></Outlet>
            <footer className="admin-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            &copy; 2023 - All Rights Reserved.
                        </div>

                        <div className="col-md-4 text-center">
                            Current working year is<Link to="#/"> <strong>{FYear}</strong></Link>
                        </div>
                        <div className="col-md-4 text-end">
                            Powered by: MaimaSoft.com
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default AdminMain;