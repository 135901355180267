import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Results = () => {

    document.title = "Results";

    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>ANNUAL RESULTS</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">ANNUAL RESULTS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3" data-aos="fade-up" data-aos-delay="200">
                            <div className="registration-card">
                                <h3>Annual Results</h3>
                                <p>Annual Result for Session 2019-20 has announced. (Enter Registration No. For Example: 010376-00 )</p>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="result-card">
                                            <h5>Enter Registration No (as per fee voucher) :</h5>
                                            <div className="input-group mt-3">
                                                <input type="text" className="form-control" placeholder="Enter here" />
                                                <button onClick={scrollToTop} className="button button-secondary" type="button">Get Result</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </>
    );
}

export default Results;
