import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import imgstudents from '../../../assets/images/illustrations/login/students.svg';
import imgbackground from '../../../assets/images/illustrations/login/background.svg';
import imglogo from '../../../assets/images/logo/Logo.png';
import { Form } from 'react-bootstrap';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import config from '../../../Config';


function Login(props) {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const signInData = {
                Email_Address: event.target.userName.value,
                Password: event.target.password.value
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'WebUser/SignIn',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: signInData
            };

            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        localStorage.setItem("AccessKey", response.data.AccessKey);
                        localStorage.setItem("ID", response.data.ID);
                        localStorage.setItem("FullName", response.data.Full_Name);
                        localStorage.setItem("User_Type", response.data.User_Type);
                        localStorage.setItem("Group_Name", response.data.Group_Name);
                        localStorage.setItem("MemberType", response.data.MemberType);
                        toast.success('You are Logged in..Wait..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        navigate('/web-admin/Dashboard');
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };
    return (
        <>
            <ToastContainer />
            <div className="content-center" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-none d-md-block">
                            <img src={imgstudents} className="img-fluid" />
                        </div>
                        <div className="col-md-5">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="login-wrap">
                                    <img src={imgbackground} />
                                    <div className="login-box">
                                        <div className="logo">
                                            <img src={imglogo} />
                                        </div>
                                        <h2 className="mb-4">Sign In</h2>
                                        <div className="form-group mb-3">
                                            <input type="text" className="form-control" placeholder="UserName" name="userName" />
                                            <i className="ri-mail-line"></i>
                                        </div>
                                        <div className="form-group mb-3">
                                            <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password" name="password" />
                                            <i className="ri-lock-password-line"></i>
                                            <span
                                                className={`${showPassword ? "ri-eye-off-line" : "ri-eye-line"
                                                    }`}
                                                onClick={togglePasswordVisibility}
                                            ></span>
                                        </div>
                                        <button type="submit" disabled={loading} className="button button-secondary w-100">SIGN IN {loading ? '...' : ''}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Login;