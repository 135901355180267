import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { scrollToTop } from '../Shared/ScrollOnTop';
import axios from 'axios';
import config from '../../../Config';
import { toast, ToastContainer } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

const Faculty = () => {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID")
    const MemberType = localStorage.getItem("MemberType");


    const [PageData, setPageData] = useState({});

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms
    }, []);


    useEffect(() => {
        document.title = "Faculty";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetFacultyforWebsite',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setPageData(response.data.ListofRecords);

                    setLoading(false);
                }
                else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/web-admin/login");
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {

            });


    }, []);
    const renderLedger = () => {
        if (PageData != null && PageData.length > 0) {
            return PageData.map((item, index) => {
                return (
                    <>
                        <div className={`simple-section ${index % 2 !== 0 ? 'bg-white' : ''}`}>
                            <div className="container">
                                <div key={index} className="section-title mb-4"><h3>{item.Name}</h3></div>
                                <div className="row g-4" key={index}>
                                    {
                                        item.ListofStaff.map((inneritem, innerindex) => {
                                            return (
                                                <>

                                                    <div className='col-md-4 col-sm-6' key={innerindex}>
                                                        <div class="faculty-card">
                                                            <h2>{inneritem.Name}</h2>
                                                            <div class="content">
                                                                <p>{inneritem.Selected_Designation?.Name}</p>
                                                                <p>{inneritem.Qualification}</p>
                                                            </div>
                                                            <div class="experience">
                                                                <span>{inneritem.Experience}</span>
                                                                <p>Experience</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <h3>FACULTY</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link onClick={scrollToTop} to="/Home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                FACULTY
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" data-aos="fade-up"> {/* Add data-aos attribute to the element */}
                            <div className="section-title">
                                <h3>Faculty</h3>
                                <div className="section-detail">
                                    <p>
                                        The Faculty
                                        One of the very essential components of the whole machinery or king pin is the
                                        teacher. He
                                        is the primadonna in the whole scenario- we can achieve the desired efforts
                                        only if we have
                                        teachers of great ability and learning – who are knowledgeable and competent
                                        enough to set an
                                        example for the students. If we want the students to pick up virtues like
                                        courage, truthfulness,
                                        and a sense of responsibility, then the teachers have to display these virtues
                                        to their pupils.
                                        Virtues, like education, are caught, not taught. All the members of the
                                        teaching staff are
                                        highly qualified with degrees in teaching. In order to see that the teachers
                                        are kept well
                                        posted with new methods of teaching techniques, the staff is provided with
                                        in-service
                                        facilities at different places during vacation. The knowledge of a teacher
                                        must grow
                                        continuously. The brilliance of the light of an oil lamp depends upon the
                                        correct level of
                                        the oil. The teaching staff is poised to attain the highest standards of
                                        modern education by
                                        dint of its devotion and commitment towards the profession where it leads to
                                        passion.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading ?
                    <div className="text-center my-5">
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                    :
                    renderLedger()
            }

        </>
    );
}

export default Faculty;
