import React from 'react'
import masjid from '../../../assets/images/masjid/masjid.png'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../Shared/ScrollOnTop';

const Mosque = () => {

    document.title = "Mosque";

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>MOSQUE</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">MOSQUE</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div>
                                <img className="img-fluid" src={masjid} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <h3>
                                    <b>Today Kasur Prayer Timings</b>
                                </h3>

                                <div className="promotion-policy">
                                    <p><b> Prayer Times For Monday 12th of DEC 2022 are: </b></p>

                                    <ul className="">
                                        <li>Fajar 05:27 </li>
                                        <li>Zuhr 11:57</li>
                                        <li>Asr 14:43</li>
                                        <li>Maghrib 17:04</li>
                                        <li>Isha 18:28</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Mosque