import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import thanks from '../../../assets/images/job/thanks.svg';
import page_1 from '../../../assets/images/job/page_1.jpg';
import page_2 from '../../../assets/images/job/page_2.jpg';
import page_3 from '../../../assets/images/job/page_3.jpg';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Jobs = () => {

    document.title = "Jobs";

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>JOBS</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link onClick={scrollToTop} to="/Home">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                JOBS
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12" data-aos="fade-right">
                            <img className="img-fluid" src={thanks} alt="" />
                        </div>
                        <div className="col-md-6 col-sm-12" data-aos="fade-left">
                            <div className="section-title">
                                <h3 className="p-0" style={{ color: '#0095df' }}>
                                    Thank you very much
                                </h3>
                            </div>
                            <p className="thk-you">
                                for showing your interest in Job at District Public school Kasur.
                                {/* We do not have any job opening right now. Kindly send your CV at{' '}
                                <strong>info@dpskasur.edu.pk</strong> */}
                            </p>
                        </div>
                    </div>
                    <div className="row gy-3">
                        <div className="col-md-6">
                            <img src={page_1} alt=""  className='img-fluid'/>
                        </div>
                        <div className="col-md-6">
                            <img src={page_2} alt=""  className='img-fluid'/>
                            <img src={page_3} alt=""  className='img-fluid'/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Jobs;
