import React from 'react'

const Banners = () => {
    return (
        <>
            <h1>Banners</h1>
        </>
    )
}

export default Banners
