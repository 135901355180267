import React from 'react'
import team from '../../../assets/images/age-criteria/team.png'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../Shared/ScrollOnTop';

const StudentAgeCriteria = () => {

    document.title = "Student Age Criteria";

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>STUDENT AGE CRITERIA</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">STUDENT AGE CRITERIA</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>Play Group</h2>
                                    <h3>(3 - 3 ½ Years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>Nursery</h2>
                                    <h3>(3 ½ – 4 Years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>Prep</h2>
                                    <h3>(4- 4 ½ Years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>One</h2>
                                    <h3>(4 -1/2 – 5 ½ Years)</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>Two</h2>
                                    <h3>(6 Years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>Three</h2>
                                    <h3>(7 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>Four</h2>
                                    <h3>(8 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>5th</h2>
                                    <h3>(9 years)</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>6th</h2>
                                    <h3>(10 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>7th</h2>
                                    <h3>(11 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>8th</h2>
                                    <h3>(12 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>9th</h2>
                                    <h3>(13 years)</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center m-auto">
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>10th</h2>
                                    <h3>(14 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>1st year &amp; 2nd year</h2>
                                    <h3>(15-17 years)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="age-card">
                                <p>Age Criteria</p>
                                <div>
                                    <img className="img-fluid" src={team} alt="" />
                                    <h2>3rd year &amp; 4th year</h2>
                                    <h3>(18-19 years)</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StudentAgeCriteria