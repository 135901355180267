import ReactDOM from 'react-dom/client';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'remixicon/fonts/remixicon.css'

import './assets/css/style.css';
import Main from './components/Website/Pages/Main';
import PrintVouchers from './components/Website/Pages/PrintVouchers';
import HomeWork from './components/Website/Pages/HomeWork';
import Results from './components/Website/Pages/Results';
import FeeStructure from './components/Website/Pages/FeeStructure';
import Introduction from './components/Website/Pages/Introduction';
import Administration from './components/Website/Pages/Administration';
import BoardOfGovernors from './components/Website/Pages/BoardOfGovernors';
import Jobs from './components/Website/Pages/Jobs';
import Faculty from './components/Website/Pages/Faculty';
import PromotionPolicy from './components/Website/Pages/PromotionPolicy';
import SchoolUniform from './components/Website/Pages/SchoolUniform';
import Syllabus from './components/Website/Pages/Syllabus';
import Home from './components/Website/Pages/Home';
import Admissions from './components/Website/Pages/Admissions';
import Library from './components/Website/Pages/Library';
import StudentAgeCriteria from './components/Website/Pages/StudentAgeCriteria';
import Prospectus from './components/Website/Pages/Prospectus';
import ComputerLab from './components/Website/Pages/ComputerLab';
import ScienceLaboratory from './components/Website/Pages/ScienceLaboratory';
import Transport from './components/Website/Pages/Transport';
import Mosque from './components/Website/Pages/Mosque';
import Playgrounds from './components/Website/Pages/Playgrounds';
import DateSheet from './components/Website/Pages/DateSheet';
import Contact from './components/Website/Pages/Contact';
import NewsDetail from './components/Website/Pages/NewsDetail';
import NewsList from './components/Website/Pages/NewsList';
import PrintChallan from './components/Website/Pages/PrintChallan';
import EventDetail from './components/Website/Pages/EventDetail';
import EventGallery from './components/Website/Pages/EventGallery';
import PhotoDetail from './components/Website/Pages/PhotoDetail';
import PhotoGallery from './components/Website/Pages/PhotoGallery';
import Meritlist from './components/Website/Pages/Meritlist';
import AdminMain from './components/Admin/AdminMain';
import Dashboard from './components/Admin/Dashboard';
import Banners from './components/Admin/Banners';
import Content from './components/Admin/ContentPage/Content';
import AddContent from './components/Admin/ContentPage/AddContent';
import EditContent from './components/Admin/ContentPage/EditContent';
import Events from './components/Admin/Events/Events';
import Videos from './components/Admin/Videos';
import News from './components/Admin/News/News';
import EditNews from './components/Admin/News/EditNews';
import AddNews from './components/Admin/News/AddNews';
import Photos from './components/Admin/Photos/Photos';
import Login from './components/Admin/Login/Login';
import AddEvents from './components/Admin/Events/AddEvents';
import EditEvents from './components/Admin/Events/EditEvents';
import EditPhotos from './components/Admin/Photos/EditPhotos';
import AddPhotos from './components/Admin/Photos/AddPhotos';
import ManageAdministration from './components/Admin/Administration/ManageAdministration';
import EditAdministration from './components/Admin/Administration/EditAdministration';
import AddAdministration from './components/Admin/Administration/AddAdministration';
import ManageBOG from './components/Admin/BOG/ManageBOG';
import EditBOG from './components/Admin/BOG/EditBOG';
import AddBOG from './components/Admin/BOG/AddBOG';
import ManageMessage from './components/Admin/Message/ManageMessage';
import EditMessage from './components/Admin/Message/EditMessage';
import AddMessage from './components/Admin/Message/AddMessage';
import SignUp from './components/Admin/Login/SignUp';
import ChangePassword from './components/Admin/ChangePassword';



function Index() {

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/PrintVouchers/PrintChallan" element={<PrintChallan />} />
        <Route path='/' element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Home/PrintVouchers" element={<PrintVouchers />} />
          <Route path="/Home/PrintVouchers/:id" element={<PrintVouchers />} />
          <Route path="/Home/HomeWork" element={<HomeWork />} />
          <Route path="/Home/FeeStructure" element={<FeeStructure />} />
          <Route path="/Home/Introduction" element={<Introduction />} />
          <Route path="/Home/Administration" element={<Administration />} />
          <Route path="/Home/BoardOfGovernors" element={<BoardOfGovernors />} />
          <Route path="/Home/Jobs" element={<Jobs />} />
          <Route path="/Home/Faculty" element={<Faculty />} />
          <Route path="/Home/Admissions" element={<Admissions />} />
          <Route path="/Home/Library" element={<Library />} />
          <Route path="/Home/PromotionPolicy" element={<PromotionPolicy />} />
          <Route path="/Home/StudentAgeCriteria" element={<StudentAgeCriteria />} />
          <Route path="/Home/Prospectus" element={<Prospectus />} />
          <Route path="/Home/SchoolUniform" element={<SchoolUniform />} />
          <Route path="/Home/Playgrounds" element={<Playgrounds />} />
          <Route path="/Home/ComputerLab" element={<ComputerLab />} />
          <Route path="/Home/Mosque" element={<Mosque />} />
          <Route path="/Home/Transport" element={<Transport />} />
          <Route path="/Home/ScienceLaboratory" element={<ScienceLaboratory />} />
          <Route path="/Home/Syllabus" element={<Syllabus />} />
          <Route path="/Home/Contact" element={<Contact />} />
          <Route path="/Home/NewsList" element={<NewsList />} />
          <Route path="/Home/Merit-list" element={<Meritlist />} />
          <Route path="/Home/NewsDetail/:id" element={<NewsDetail />} />
          <Route path="/Home/EventDetail/:id" element={<EventDetail />} />
          <Route path="/Home/PhotoDetail/:id" element={<PhotoDetail />} />
          <Route path="/Examination/DateSheet" element={<DateSheet />} />
          <Route path="/Examination/Results" element={<Results />} />
          <Route path="/Home/EventGallery" element={<EventGallery />} />
          <Route path="/Home/PhotoGallery" element={<PhotoGallery />} />

        </Route>


        <Route path='/web-admin/login' element={<Login />} />
        {/* <Route path='/web-admin/sign-up' element={<SignUp />} /> */}


        <Route path="/web-admin" element={<AdminMain />}>

          <Route path='/web-admin/change-password' element={<ChangePassword />} />

          <Route path="/web-admin/dashboard" element={<Dashboard />} />
          <Route path="/web-admin/banners" element={<Banners />} />

          <Route path="/web-admin/content" element={<Content />} />
          <Route path="/web-admin/add-Content" element={<AddContent />} />
          <Route path="/web-admin/edit-Content/:id" element={<EditContent />} />

          <Route path="/web-admin/videos" element={<Videos />} />


          <Route path="/web-admin/events" element={<Events />} />
          <Route path="/web-admin/edit-events/:id" element={<EditEvents />} />
          <Route path="/web-admin/add-events" element={<AddEvents />} />

          <Route path="/web-admin/news" element={<News />} />
          <Route path="/web-admin/news/:id" element={<EditNews />} />
          <Route path="/web-admin/add-news" element={<AddNews />} />

          <Route path="/web-admin/administration" element={<ManageAdministration />} />
          <Route path="/web-admin/administration/:id" element={<EditAdministration />} />
          <Route path="/web-admin/add-administration" element={<AddAdministration />} />

          <Route path="/web-admin/bog" element={<ManageBOG />} />
          <Route path="/web-admin/bog/:id" element={<EditBOG />} />
          <Route path="/web-admin/add-bog" element={<AddBOG />} />

          <Route path="/web-admin/message" element={<ManageMessage />} />
          <Route path="/web-admin/message/:id" element={<EditMessage />} />
          <Route path="/web-admin/add-message" element={<AddMessage />} />


          <Route path="/web-admin/photos" element={<Photos />} />
          <Route path="/web-admin/edit-photos/:id" element={<EditPhotos />} />
          <Route path="/web-admin/add-photos" element={<AddPhotos />} />
        </Route>
      </Routes>
    </BrowserRouter>

  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);
