import React, { useState, useEffect } from 'react';
import { Link } from "react";
import Header from '../include/Header';
import Footer from '../include/Footer';
import { Outlet } from 'react-router-dom';

const Main = () => {

  
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default Main;
