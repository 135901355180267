import React, { useState, useEffect } from "react";
import books from '../../../assets/images/Library/books.JPG'
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "react-bootstrap";
import Pagination from "../../General/Pagination";
import { RenderHTMLString } from "../../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';

const Library = () => {

    document.title = "Library";

    const [AccessKey, setAccessKey] = useState("239f5be8-5389-44ea-8935-b5fe638339da");
    const [UserID, setUserID] = useState("1");
    const [MemberType, setMemberType] = useState("Website");

    const [designationID, setDesignationId] = useState(0);
    const [empTypeID, seEmployeeTypeId] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [showFilters, setShowFilters] = useState(true);

    // pagination 
    const [pageno, setPageNo] = useState(1);
    const [pagesize, setPageSize] = useState(10);
    const [totalrecords, setTotalRecords] = useState(0);

    const [bookName, setBookName] = useState("");
    const [writerName, setWriterName] = useState("");
    const [publisherName, setPublisherName] = useState("");
    const [bookNo, setBookNo] = useState("");
    const [status, setStatus] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [ContentPage, setContentPage] = useState({});

    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: "3"

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'ContentPage/GetContentPageByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setContentPage(response.data.ContentPage);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    useEffect(() => {

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageno,
            pageSize: pagesize,
            Book_Name: bookName,
            Writer_Name: writerName,
            Publisher_Name: publisherName,
            Book_No: bookNo,
            Category: "",
            Status: status,
            OrderBy: orderBy
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetAllBooks',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                //console.log(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageno, pagesize, isUpdated, bookName, writerName, publisherName, bookNo, status, orderBy]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.ID}</td>
                            <td>{item.Book_No}</td>
                            <td>{item.Writer_Name}</td>
                            <td>{item.Book_Name}</td>
                            <td>{item.Publisher_Name}</td>
                            <td>{item.Current_Status}</td>
                            <td>{item.Remarks}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <h3>LIBRARY</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">LIBRARY</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section class="simple-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6 col-sm-12">
                            <b>{ContentPage.Page_Title}</b>
                            <p>
                                {RenderHTMLString(ContentPage.Page_Detail)}
                            </p>
                            </div>
                        <div class="col-md-6 col-sm-12">
                            <div>
                                <img class="img-fluid rounded-2" src={books} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-2">
                                <div className="card-body">
                                    <div className="">
                                        <div className="row gy-3 align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="">Book Name:</label>
                                                    <input type="text" onChange={(e) => setBookName(e.target.value)} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="">Writer Name:</label>
                                                    <input type="text" onChange={(e) => setWriterName(e.target.value)} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="">Publisher:</label>
                                                    <input type="text" onChange={(e) => setPublisherName(e.target.value)} className="form-control"
                                                        required />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="">Book No:</label>
                                                    <input type="text" onChange={(e) => setBookNo(e.target.value)} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="">Status:</label>
                                                    <select name="Status" className="form-select"
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    >
                                                        <option Value="">--All--</option>
                                                        <option Value="Available">Available</option>
                                                        <option Value="Issued">Issued</option>
                                                        <option Value="Lost">Lost</option>
                                                        <option Value="Disposed">Disposed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label for="" className="">Order By:</label>
                                                    <select name="OrderBy" className="form-select"
                                                        onChange={(e) => setOrderBy(e.target.value)}
                                                    >
                                                        <option Value="Book_No">BookNo</option>
                                                        <option Value="Book_Name">Book Name</option>
                                                        <option Value="Writer_Name">Writer Name</option>
                                                        <option Value="Publisher_Name">Publisher Name</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2 offset-md-4 text-end">
                                                <div className="form-group mt-3">
                                                    <button type="submit" className="w-100 button button-secondary">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content pb-5">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme">
                                <thead>
                                    <tr>
                                        <th colSpan="8">
                                            {totalrecords} Records Found!
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>ID</th>
                                        <th>BookNo</th>
                                        <th>Writer Name</th>
                                        <th>Book Name</th>
                                        <th>Publisher</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colSpan="8">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }

                                    {/* <tr>
                                            <td colSpan="8">
                                                <input type="button" value="Print List" className="button button-primary mt-4"
                                                />
                                            </td>
                                        </tr> */}
                                </tbody>
                            </table>
                            <div className="pt-2" style={{ borderTop: "1px solid rgba(0, 0, 0, 0.10)" }}>
                                {
                                    totalrecords > 10 &&
                                    <div className="pagination-wrap">

                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));
                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageno}
                                                pageSize={pagesize}
                                                totalRecords={totalrecords}
                                                onPageChange={handlePageChange}
                                            />

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Library