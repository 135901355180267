import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { scrollToTop } from '../Shared/ScrollOnTop';

const Contact = () => {

  document.title = "Contact";

  useEffect(() => {
    AOS.init();
  }, []);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };


  return (
    <>
      <div className="page-head">
        <div className="container">
          <h3>CONTACT US</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link onClick={scrollToTop} to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                CONTACT US
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="simple-section bg-white">
        <div className="container">
          <div className="section-title">
            <h3 className="text-center">Send Your Message</h3>
          </div>
          <div className="row mt-5">
            <div className="col-md-4" data-aos="fade-right" data-aos-delay="200">
              <div className="contact">
                <div className="mb-3">
                  <i className="ri-map-pin-line"></i>
                </div>
                <div>
                  <h5>Our Location</h5>
                </div>
                <div>
                  <p>Main ferozpur Road Officers Colony Kasur</p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
              <div className="contact">
                <div className="mb-3">
                  <i className="ri-customer-service-2-line"></i>
                </div>
                <div>
                  <h5>Phone Number</h5>
                </div>
                <div className="number">
                  <p>0499250165</p>
                  <p>0499250165</p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-left" data-aos-delay="600">
              <div className="contact">
                <div className="mb-3">
                  <i className="ri-mail-line"></i>
                </div>
                <div>
                  <h5>Email Address</h5>
                </div>
                <div>
                  <p>info@dpskasur.edu.pk</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="simple-section mb-5">
        <div className="container">
          <div className="row gy-3">
            <div className="col-md-5" data-aos="fade-right" data-aos-delay="800">
              <div className="card shadow-sm">
                <div className="card-body p-4">
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="gy-3">
                      <div className="section-title title-left p-0">
                        <h5>Send us a message</h5>
                      </div>
                      <Form.Group className='form-group' as={Col} md="12" controlId="validationCustom01">
                        <Form.Label>Full name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Fahad Ali"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='form-group' as={Col} md="12" controlId="validationCustom02">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="03001234567"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='form-group' as={Col} md="12" controlId="validationCustom03">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          required
                          type="Email"
                          placeholder="forexample@gmail.coom"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className='form-group' as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Subject"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <div className="form-group">
                        <label htmlFor="">Massage</label>

                        <textarea
                          required
                          className="form-control"
                          style={{ minHeight: '138px' }}
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                        />
                      </div>
                      <Button onClick={scrollToTop} className='button button-secondary' type="submit">Submit form</Button>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-7" data-aos="fade-left" data-aos-delay="1000">
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27323.72822286509!2d74.469781!3d31.124569999999995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xffb74aec3a7d09e9!2sDistrict%20Public%20School%20%26%20College%2C%20Kasur!5e0!3m2!1sen!2sus!4v1664455594465!5m2!1sen!2sus"
                  width="100%"
                  height="700"
                  style={{ border: '0' }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
