import React from 'react'
import Policy from '../../../assets/images/policy/Policy.svg'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../Shared/ScrollOnTop';

const PromotionPolicy = () => {

    document.title = "Promotion Policy";

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>Promotion Policy</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Promotion Policy</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-md-6 col-sm-12">
                            <img className="img-fluid" src={Policy} alt=""/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h4><b>PROMOTION POLICY</b></h4>
                            <div className="promotion-policy">
                                <ul>
                                    <li>
                                        Student failing twice in annual examination, students displaying determined apathy for progress,
                                        student failing in two successive terminal examinations will be checked out and careful
                                        observation will be made in this regard.
                                    </li>
                                    <li>
                                        Pass percentage of individual subject for class play Group to Class VII is 50% and for class
                                        VIII is 45% in all examinations of the year.
                                    </li>
                                    <li>In all classes annual system will be introduced.</li>
                                    <li>
                                        A students of terminal examination system securing " E" grade in any one subject will be
                                        considered for promotion in the annual examination provided he / she secures at least 33% marks
                                        in that subject. Such case may be considered for promotion on the recommendation of Academic
                                        Council.
                                    </li>
                                    <li>A student failing in two / three subjects will be detained.</li>
                                    <li>
                                        Students will have to clear in all the papers of send up examination before the board
                                        Examination.
                                    </li>
                                    <li>80% attendance is compulsory for eligibility to appear in all examinations.</li>
                                    <li>In secondary classes the computer will be taken as optional subject.</li>
                                    <li>For secondary classes (BISE) Lahore policy will be followed.</li>
                                    <li>Any change /amendment in assessment / promotion policy shall be notified accordingly.</li>
                                    <li>Internal grade to secondary class will be awarded on the basis of send up examinations.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PromotionPolicy