import React from 'react'
import dps_logo from '../../../assets/images/logo/dps-logo.png'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../Shared/ScrollOnTop';

const Prospectus = () => {

    document.title = "Prospectus";

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>PROSPECTUS</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">PROSPECTUS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3 m-auto">
                            <div className="prospectus">
                                <p>2022 Prospectus</p>
                                <div>
                                    <img className="img-fluid" src={dps_logo} alt="" />
                                    <button onClick={scrollToTop} href="#" className="button-icon button-secondary w-100 mt-md-3">
                                        Download
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Prospectus