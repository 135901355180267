import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { scrollToTop } from '../Shared/ScrollOnTop';

function Footer(props) {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 m-auto">
              <div className="help">
                <div className="row align-items-center">
                  <div className="col-md-8" data-aos="fade-up" data-aos-delay="200">
                    <div className="footer-contact">
                      <h3>How can we Help?</h3>
                      <p>For more information or inquiry about the school, please feel free to get in touch with us</p>
                      <h5>Phone: 0499250165</h5>
                      <h5>Email: info@dpskasur.edu.pk</h5>
                    </div>
                  </div>
                  <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
                    <NavLink onClick={scrollToTop} className="button-icon button-secondary" to="/Home/Contact">
                      Contact US
                      <span><i className="ri-arrow-right-s-line"></i></span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line">
            <div className="row">
              <div className="col-md-3" data-aos="fade-up" data-aos-delay="600">
                <div className="Quick" data-aos="zoom-in" data-aos-delay="800">
                  <h4>The School</h4>
                  <ul>
                    <li data-aos="fade-right" data-aos-delay="1000">
                      <NavLink onClick={scrollToTop} to="/Home/Introduction/">Introduction</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1200">
                      <NavLink onClick={scrollToTop} to="/Home/Administration">Administration</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1400">
                      <NavLink onClick={scrollToTop} to="/Home/Faculty">Faculty</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1600">
                      <NavLink onClick={scrollToTop} to="/Home/PromotionPolicy">Promotion Policy</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1800">
                      <NavLink onClick={scrollToTop} to="/Home/SchoolUniform">School Uniform</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2000">
                      <NavLink onClick={scrollToTop} to="/Home/Admissions">Admissions</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2200">
                      <NavLink onClick={scrollToTop} to="/Home/Jobs">Jobs</NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3" data-aos="fade-up" data-aos-delay="800">
                <div className="Quick" data-aos="zoom-in" data-aos-delay="1000">
                  <h4>Admissions</h4>
                  <ul>
                    <li data-aos="fade-right" data-aos-delay="1200">
                      <NavLink onClick={scrollToTop} to="/Home/FeeStructure">Fee Structure</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1400">
                      <NavLink onClick={scrollToTop} to="/Home/Library">List of Books</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1600">
                      <NavLink onClick={scrollToTop} to="/Home/StudentAgeCriteria">Student Age Criteria</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1800">
                      <NavLink onClick={scrollToTop} to="#">Allocation of Seats</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2000">
                      <NavLink onClick={scrollToTop} to="/Home/Prospectus">Prospectus</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2200">
                      <NavLink onClick={scrollToTop} to="/Home/Syllabus">Syllabus</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3" data-aos="fade-up" data-aos-delay="1000">
                <div className="Quick" data-aos="zoom-in" data-aos-delay="1200">
                  <h4>Facilities</h4>
                  <ul>
                    <li data-aos="fade-right" data-aos-delay="1400">
                      <NavLink onClick={scrollToTop} to="/Home/ComputerLab">Computer Lab</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1600">
                      <NavLink onClick={scrollToTop} to="/Home/ScienceLaboratory">Science Laboratories</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1800">
                      <NavLink onClick={scrollToTop} to="/Home/Library">Library</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2000">
                      <NavLink onClick={scrollToTop} to="/Home/Transport">Transport</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2200">
                      <NavLink onClick={scrollToTop} to="/Home/Mosque">The Mosque</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2400">
                      <NavLink onClick={scrollToTop} to="/Home/Playgrounds">Play Grounds</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3" data-aos="fade-up" data-aos-delay="1200">
                <div className="Quick" data-aos="zoom-in" data-aos-delay="1400">
                  <h4>Account</h4>
                  <ul>
                    <li data-aos="fade-right" data-aos-delay="1600">
                      <NavLink onClick={scrollToTop} to="#">Staff Login</NavLink>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1800">
                      <NavLink onClick={scrollToTop} to="#">Student/Parent Login</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="text-center text-white pt-3 pb-3" style={{ fontSize: ".9rem" }}>
              Copyrights 2022-2023 District Public School &amp; College Kasur. All
              Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
