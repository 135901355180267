import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bulding from '../../../assets/images/Introduction/bulding.svg'
import boy_wing from '../../../assets/images/Introduction/boy-wing.jpg'
import prep_wing from '../../../assets/images/Introduction/prep-wing.jpg'
import junior_wing from '../../../assets/images/Introduction/junior-wing.jpg'
import girls_clg from '../../../assets/images/Introduction/girls-clg.jpg'
import girls_wing from '../../../assets/images/Introduction/girls-wing.jpg'
import { scrollToTop } from '../Shared/ScrollOnTop';

const Introduction = () => {
    
    document.title = "Introduction";

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            easing: 'ease-in-out',
        });
    }, []);
    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>INTRODUCTION</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">INTRODUCTION</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="simple-section bg-white" data-aos="fade-up">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div>
                                <img className="img-fluid rounded-2" src={bulding} alt="" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="section-detail">
                                <p>
                                    In this age of excelling competition for quality education, the education conscious inhabitants of Kasur, the selfless humanitarians councilors headed by the perspicacious and visionary the then deputy commissioner of Kasur Mr. G.M Sikandar got together to have a high-quality educational institution in the city, the foundation stone of which was laid at the very place where in 1947, the caravans of Muslims from India stopped for respite.
                                </p>
                                <p>
                                    In 1988, it started its journey as a middle school and a new glorious chapter was opened in the history of Kasur for imparting top-quality education to the long waiting dwellers of the city and its suburban villages. It was decided that the institution would be a place for all-rounded personality growth of the students. Soon it became cynosure of the eyes of masses and was upgraded to high school and the intermediate and B.Sc level.
                                </p>
                                <p>
                                    The school boasts of a talent-oriented teaching staff capable of inculcating both Islamic and modern values of pioneering virility of science and technology in the minds of the students. Since its inception, the school has been providing the motherland with the polished talent in the shape of students who are a source of pride for the nation.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="section-detail">
                                <p>
                                    DPS and BSD College Kasur is not unknown in the Medical Colleges and Engineering Universities throughout Pakistan as every year our students find a due share of admission in prestigious institutions. The school is one of its kind in the whole district.
                                </p>
                                <p>
                                    Its spacious grounds and beautiful lawns lend an enchanting beauty and leave lasting imprints of broadmindedness on the mind of students. A deep sense of their being the custodians of the rich legacies bequeathed to them from their ancestors is inculcated in the minds of the students. Quality education is the hallmark and idiosyncrasy of this Alma Mater. The ever-increasing number of students is a living testimony to the veracity of the claim of the school to be the best in the region.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section tree">
                <div className="container">
                    <div className="row align-items-center gy-4" data-aos="fade-up">
                        <div className="col-md-4">
                            <div className="wing-card">
                                <img className="img-fluid" src={prep_wing} alt="" />
                                <div className="wing-text section-detail">
                                    <h5>
                                        <b>Prep Wing</b>
                                    </h5>
                                    <span>Classes: Play, Nursery, Prep</span>
                                    <p className="">
                                        The little kids embark on their journey of learning from these wings. Our trained and qualified teachers provide an affectionate and encouraging atmosphere to little blooms and buds to help them grow confidently.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wing-card">
                                <img className="img-fluid" src={junior_wing} alt="" />
                                <div className="wing-text section-detail">
                                    <h5>
                                        <b>Junior Wing</b>
                                    </h5>
                                    <span>Classes: I- IV</span>
                                    <p className="">
                                        Love for learning and a passion to excel are developed in the budding artists, leaders, scientists, and engineers of Junior Wing. Here, they are given the desired opportunities to discover and nurture their individual flairs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wing-card">
                                <img className="img-fluid" src={girls_wing} alt="" />
                                <div className="wing-text section-detail">
                                    <h5>
                                        <b>Girls Wing</b>
                                    </h5>
                                    <span>Classes: V- X</span>
                                    <p className="">
                                        The sense of growing up and being part of the senior wing gives the girls a new identity which they love to embrace. Through their house parliament and sports activities, they discover aspects of their personality hitherto unknown to them.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center gy-4 mt-1" data-aos="fade-up">
                        <div className="col-md-4">
                            <div className="wing-card">
                                <img className="img-fluid" src={girls_clg} alt="" />
                                <div className="wing-text section-detail">
                                    <h5>
                                        <b>Girls College</b>
                                    </h5>
                                    <span>Classes: XI, XII</span>
                                    <p className="">
                                        The Girls College double storey structure stands unique in grandeur. The students of Girls College always carry a unique persona, and their desire to be honors for their institution makes them do extraordinarily well in academic and co-curricular activities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="wing-card">
                                <img className="img-fluid" src={boy_wing} alt="" />
                                <div className="wing-text section-detail">
                                    <h5>
                                        <b>Boys Wing</b>
                                    </h5>
                                    <span>Classes: V- XII</span>
                                    <p className="">
                                        Boys will be boys! Being in an atmosphere of camaraderie and bonding with the new grand structure gives them a sense of ownership, a wing solely for the boys of DPS. A host of opportunities are waiting for them to prove their potential to the world.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="simple-section bg-white">
                <div className="container">
                    <div className="row align-items-center pt-5" data-aos="fade-up">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h3 className="">AIMS &amp; OBJECTIVES</h3>
                            </div>
                            <p>
                                The aim with which the institution came into being was to provide less expensive and high quality education with English as medium of instruction at the district level. The school still upholds its commitment and tries its best to provide congenial and conducive environment to students for a broad-based education second to none. The teaching learning knot is interest and burden free with a through coverage of laid down syllabi. Our objective is to create in our future generations a close association with the religion Value.
                                Religious education and sense of Islamic values is provided by teaching if Holy Quran ,Hifz and Nazra to make students know the basic Islamic teachings injunctions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h3 className="text-center pb-md-3">NEW ACADEMIC SCHEDULE FOR CLASS ONE TO EIGHT</h3>
                            </div>

                            <div className="card mt-4 mb-4">
                                <div className="card-body schedule-mobile">
                                    <table className="table table-theme table-condensed mb-md-0">
                                        <tbody>
                                            <tr data-aos="fade-up" data-aos-delay="100">
                                                <td rowspan="2">
                                                    <h1 className="text-center">
                                                        <b>
                                                            First Semester
                                                        </b>
                                                    </h1>
                                                    <p className="text-center">
                                                        11th April to 30th October
                                                    </p>
                                                </td>
                                                <td>
                                                    <table className="table table-theme table-condensed m-0 pb-md-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Mid-Term Test</th>
                                                                <th>11th April to 31th May</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr data-aos="fade-up" data-aos-delay="200">
                                                                <td>
                                                                    Regular Class
                                                                </td>
                                                                <td>
                                                                    (11th April to 31st May)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="300">
                                                                <td>
                                                                    Summer Vacation
                                                                </td>
                                                                <td>
                                                                    (1st June to 31st July)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="400">
                                                                <td>
                                                                    Mid Term Exam Schedule
                                                                </td>
                                                                <td>
                                                                    (3rd August to 12th August)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="500">
                                                                <td>
                                                                    Parent Teachers Meeting
                                                                </td>
                                                                <td>
                                                                    -----------
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr data-aos="fade-up" data-aos-delay="600">
                                                <td className="pb-md-0">
                                                    <table className="table table-theme table-condensed m-0 pb-md-0">
                                                        <thead>
                                                            <tr>
                                                                <th>First-Term Exams</th>
                                                                <th>(15th August to 9th October)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr data-aos="fade-up" data-aos-delay="700">
                                                                <td>
                                                                    Regular Class
                                                                </td>
                                                                <td>
                                                                    (15th to 9th October)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="800">
                                                                <td>
                                                                    First Term Examination
                                                                </td>
                                                                <td>
                                                                    (10th October to 30th October)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="900">
                                                                <td>
                                                                    Parent Teachers Meeting
                                                                </td>
                                                                <td>
                                                                    -----------
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr data-aos="fade-up" data-aos-delay="1000">
                                                <td rowspan="2" style={{ borderBottom: "transparent" }}>
                                                    <h1 className="text-center">
                                                        <b>
                                                            SECOND SEMESTER
                                                        </b>
                                                    </h1>
                                                    <p className="text-center">
                                                        31th October to 28th February
                                                    </p>
                                                </td>
                                                <td className="pt-md-4">
                                                    <table className="table table-theme table-condensed m-0 pb-md-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Mid-Term Test</th>
                                                                <th>(31st October to 10th December)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr data-aos="fade-up" data-aos-delay="1100">
                                                                <td>
                                                                    Regular Class
                                                                </td>
                                                                <td>
                                                                    (31st October to 10th December)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="1200">
                                                                <td>
                                                                    Mid-Term Examination
                                                                </td>
                                                                <td>
                                                                    (12th December To 21st December)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="1300">
                                                                <td>
                                                                    Parent Teachers Meeting
                                                                </td>
                                                                <td>
                                                                    -----------
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="1400">
                                                                <td>
                                                                    Winter Vacation
                                                                </td>
                                                                <td>
                                                                    (23rd December to 31st December)
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr data-aos="fade-up" data-aos-delay="1500">
                                                <td className="pb-md-0">
                                                    <table className="table table-theme table-condensed m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Second / Final Term Exams</th>
                                                                <th>(1st January to 31st March)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr data-aos="fade-up" data-aos-delay="1600">
                                                                <td>
                                                                    Regular Class
                                                                </td>
                                                                <td>
                                                                    (1st January to 28th February)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="1700">
                                                                <td>
                                                                    Final Term Examination
                                                                </td>
                                                                <td>
                                                                    (1st March to 18th March)
                                                                </td>
                                                            </tr>
                                                            <tr data-aos="fade-up" data-aos-delay="1800">
                                                                <td>
                                                                    Parent Teachers Meeting
                                                                </td>
                                                                <td>
                                                                    -----------
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-12">
                                    <h4>
                                        <b>
                                            Instruction:
                                        </b>
                                    </h4>
                                    <div className="promotion-policy">
                                        <ul>
                                            <li>During the all <b>Mid Term Exams</b> there will be no leave in datesheets, paper will be on daily bases.</li>
                                            <li>Mid Term Question paper will consist of 50 Marks.</li>
                                            <li>Prescribed Syllabus will be given to students for both Mid-Terms and First/Second Term Exams.</li>
                                            <li>First Term Syllabus will be excluded in 2nd Term Examination.</li>
                                            <li>Final Result will be prepared by adding marks obtained in First Term, Second Term, and both Midterm Exams.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Introduction