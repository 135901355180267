const config = {
  //base_url: "http://localhost:52476/api/",
  // MEDIA_ROOT_URL: "http://localhost:8000/",

  // base_url: "https://api.dpskasur.edu.pk/api/",
  // path: "https://www.dpskasur.edu.pk/",
  // StaffImagePath: "https://api.dpskasur.edu.pk/staff_images/",
  // StudentImagePath: "https://api.dpskasur.edu.pk/student_images/",
  // images: "https://api.dpskasur.edu.pk/",
  // assets: "https://api.dpskasur.com/assets/",
  // root: "https://api.dpskasur.edu.pk/",


  base_url: "https://api.nscholar.com/api/",
  path: "https://www.dpskasur.edu.pk/",
  StaffImagePath: "https://api.nscholar.com/staff_images/",
  StudentImagePath: "https://api.nscholar.com/student_images/",
  images: "https://api.nscholar.com/",
  assets: "https://api.nscholar.com/assets/",
  root: "https://api.nscholar.com/",
  date_format_input: "YYYY-MM-DD",
  // Editor_Apikey: '6o1149t4fhc3r0pkpyiiu5pdr5exjqzb24dj3dwdopm6nb3v',
  Editor_Apikey: 'w81u1otpwt8qqd0odorwvyaol3vsrjipuhdua1mr6kjqnebw',

  //sandbox credentials
  // Password : "91901a8v50",
  // MerchantID: "MC45117",
  // IntegeritySalt: "uu0sz5tvas",
  //JazzMWalletURL : "https://sandbox.jazzcash.com.pk/",


  //live credentials
  Password: "f9w0t42z11",
  MerchantID: "54782577",
  IntegeritySalt: "h3s5xvx61s",


  ReturnURL: "https://www.dpskasur.edu.pk/payment/jazzcashreturn",
  IPNURL: "https://www.dpskasur.edu.pk/payments/jazzcashipn",
  JazzMWalletURL: "https://payments.jazzcash.com.pk/",
  // changes in config file

  // theexpertfahad@gmail.com
  // 123456789

  // 03123456780	345678	Authentication Error
  // Others	345678	Pending
  // 03123456789  345678  success 


};
export default config;