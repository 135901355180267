import React, { useState, useEffect } from "react";

const Pagination = ({ pageNo, pageSize, totalRecords, onPageChange }) => {

  
  const totalPages = Math.ceil(totalRecords / pageSize);
  const [currentPage, setCurrentPage] = useState(pageNo);

  //onsole.log(Math.ceil(totalRecords / pageSize));

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  useEffect(() => {
    setCurrentPage(1); // Reset the current page when pageSize changes
  }, [pageSize]);


  const handleFirstPage = () => {
    handlePageChange(1); // Go to the first page
  };

  const handleLastPage = () => {
    handlePageChange(totalPages); // Go to the last page
  };


  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Set the maximum number of page numbers to show
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    // Add the current page number
    pageNumbers.push(currentPage);

    // Add the page numbers before the current page
    for (let i = 1; i <= halfMaxPagesToShow; i++) {
      if (currentPage - i > 0) {
        pageNumbers.unshift(currentPage - i);
      }
    }

    // Add the page numbers after the current page
    for (let i = 1; i <= halfMaxPagesToShow; i++) {
      if (currentPage + i <= totalPages) {
        pageNumbers.push(currentPage + i);
      }
    }

    // Add the first and last page numbers if they're not already included
    if (!pageNumbers.includes(1)) {
      pageNumbers.unshift(1);
    }
    if (!pageNumbers.includes(totalPages)) {
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  return (
    <>
      <div className="col-md-3 form_default">
          <div className="d-flex align-items-center">
            
            <div className="d-flex align-items-center">
              <label className="ms-2" style={{fontSize: "15px"}}>{totalRecords} items in {totalPages} pages</label>

            </div>
          </div>

        </div>
        <div className="col-md-7">
          <ul className="pagination justify-content-end mb-0">
            {/*  className={`page-item ${currentPage === 1 ? "active" : ""}`} */}
            <li className="page-item">
              {/* Render the "First" button */}
              <button className="page-link" data-tip="First Page"
                disabled={currentPage === 1}
                onClick={handleFirstPage}>
                <i className="ri-skip-left-line"></i>
              </button>
            </li>



            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <i className="ri-arrow-left-s-line"></i>
              </button>
            </li>

            {/* Render the first page number if it's not already shown */}
            {getPageNumbers()[0] !== 1 && (
              <li className="page-item disabled">
                <button className="page-link">...</button>
              </li>
            )}

            {/* Render the page numbers */}
            {getPageNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${pageNumber === currentPage ? "active" : ""
                  }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}

            {/* Render the last page number if it's not already shown */}
            {getPageNumbers().slice(-1)[0] !== totalPages && (
              <li className="page-item disabled">
                <button className="page-link">...</button>
              </li>
            )}

            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <i className="ri-arrow-right-s-line"></i>
              </button>
            </li>

            {/* className={`page-item ${currentPage === totalPages ? "active" : ""}`} */}
            <li className="page-item">
              {/* Render the "Last" button */}
              <button className="page-link" data-tip="Last Page"
                disabled={currentPage === totalPages}
                onClick={handleLastPage}>
                <i className="ri-skip-right-line"></i>
              </button>
            </li>
          </ul>
        </div>
    </>
  );
};

export default Pagination;
