import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import config from '../../Config';


function ChangePassword(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID")
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [NewPassword, setNewPassword] = useState(false);
    const [ConforimPassword, setConforimPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    let navigate = useNavigate();

    const handleSubmit = (event) => {


        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const form = event.currentTarget;
            const ConforimPassword = form.ConforimPassword.value;
            const NewPassword = form.NewPassword.value;



            if (ConforimPassword !== NewPassword) {
                toast.error('New password does not match the Conforim Password', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
                return;
            }


            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                OldPassword: event.target.OldPassword.value,
                NewPassword: event.target.NewPassword.value,
            };

            console.log(data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'WebUser/ChangePassword',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setTimeout(() => {
                            navigate('/web-admin/login');
                        }, 3000);
                    }
                    else if (response.data.status_message === "Invalid Access Key.") {
                        navigate("/web-admin/login");
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoading(false);
                    }




                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };
    return (
        <>
            <ToastContainer />
            <div className="admin-page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/web-admin/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Change Password
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">Change Password</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="card mb-2">
                            <div className="card-body p-md-4">
                                <div className="row gy-3 align-items-center">
                                    <div className="col-md-4">
                                        <div className="form-group position-relative">
                                            <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Old Password" name="OldPassword" />
                                            <span
                                                style={{ position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }}
                                                className={`${showPassword ? "ri-eye-off-line" : "ri-eye-line"
                                                    }`}
                                                onClick={togglePasswordVisibility}
                                            ></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group position-relative">
                                            <input type={NewPassword ? "text" : "password"} className="form-control" placeholder="New Password" name="NewPassword" />
                                            <span
                                                style={{ position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }}
                                                className={`${NewPassword ? "ri-eye-off-line" : "ri-eye-line"
                                                    }`}
                                                onClick={() => setNewPassword(!NewPassword)}
                                            ></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group position-relative">
                                            <input type={ConforimPassword ? "text" : "password"} className="form-control" placeholder="Conforim Password" name="ConforimPassword" />
                                            <span
                                                style={{ position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }}
                                                className={`${ConforimPassword ? "ri-eye-off-line" : "ri-eye-line"
                                                    }`}
                                                onClick={() => setConforimPassword(!ConforimPassword)}
                                            ></span>
                                        </div>
                                    </div>

                                    <div className="col-md-2 offset-md-10">
                                        <button type="submit" disabled={loading} className="button button-primary w-100">Update {loading ? '...' : ''}</button>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;