import React from 'react'
import science_laboratory_1 from '../../../assets/images/science-lab/science-laboratory-1.JPG'
import science_laboratory_2 from '../../../assets/images/science-lab/science-laboratory-2.JPG'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../Shared/ScrollOnTop';

const ScienceLaboratory = () => {

    document.title = "Science Laboratory";

    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>COMPUTER LAB</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">COMPUTER LAB</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12">
                            <div className="uniform">
                                <div id="science-laboratory" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#science-laboratory" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#science-laboratory" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="img-fluid rounded-2" src={science_laboratory_1} alt=""/>
                                        </div>
                                        <div className="carousel-item">
                                            <img className="img-fluid rounded-2" src={science_laboratory_2} alt=""/>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#science-laboratory" data-bs-slide="prev">
                                        <span className="ri-arrow-left-s-line prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#science-laboratory" data-bs-slide="next">
                                        <span className="ri-arrow-right-s-line next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="">
                                <h3>
                                    <b>Science Lab</b>
                                </h3>
                                <p>
                                    The school has well equipped laboratories for Physics, Chemistry and Biology wherein the students are encouraged to learn through experiments and understand various concepts beyond their books away from the conventional classroom teaching. Every year these labs are furnished with the latest instruments, specimens, charts etc.
                                </p>

                                <p>Science lab equipments allow students to interact directly with the data gathered. They get a first-hand learning experience by performing various experiments on their own. Students are made to use the models and understand different scientific theories and concepts, thus making teaching and learning easy both for the teachers, as well as for the students. There are several scientific theories and concepts that are difficult to explain directly from the books.</p>
                                <p>Children develop interest in scientific research in science labs. When they observe various things and carry out different experiments, their reasoning skills are honed and they start thinking deeply on those theories and concepts.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ScienceLaboratory