import React, { useState, useEffect } from "react";
import books from '../../../assets/images/Library/books.JPG'
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "react-bootstrap";
import Pagination from "../../General/Pagination";
import { RenderHTMLString } from "../../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';

const DateSheet = () => {

    document.title = "DateSheet";

    const [AccessKey, setAccessKey] = useState("239f5be8-5389-44ea-8935-b5fe638339da");
    const [UserID, setUserID] = useState("1");

    const [loading, setLoading] = useState(false);

    // pagination 

    const [ContentPage, setContentPage] = useState({});

    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: "Website",
            ID: "4"

        };
        console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'ContentPage/GetContentPageByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setContentPage(response.data.ContentPage);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);


    return (
        <>
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <h3>DATE SHEET</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">DATE SHEET</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>
                                <b>
                                    {ContentPage.Page_Title}
                                </b>
                            </h2>
                            <div className="card mt-4 mb-4">
                                <div className="card-body">
                                    <div className="date-sheetpage">
                                        {RenderHTMLString(ContentPage.Page_Detail)}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default DateSheet