import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Helmet } from 'react-helmet';
import { RenderHTMLString } from "../../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';

const PhotoDetail = () => {

    let { id } = useParams()
    const [AccessKey, setAccessKey] = useState("239f5be8-5389-44ea-8935-b5fe638339da");
    const [UserID, setUserID] = useState("1");
    const [MemberType, setMemberType] = useState("Website");

    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ContentPage, setContentPage] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [Title, setTitle] = useState(0);
    const [listOfEvents, LedgerEvents] = useState([]);
    const [News, setPhoto] = useState({});
    const [listOfPhoto, LedgerPhoto] = useState([]);

    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: id,
            MemberType: MemberType,
        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'PhotoGallery/GetPhotoByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log("our data", response.data);
                if (response.data.status_code == 1) {
                    setPhoto(response.data.News);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, [id]);


    useEffect(() => {

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: 6,
        };
        //console.log(data)

        var api_config = {
            method: "post",
            url: config.base_url + "PhotoGallery/GetAllPhoto",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                LedgerPhoto(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, Title]);


    const renderPhoto = () => {
        if (listOfPhoto != null && listOfPhoto.length > 0) {

            return listOfPhoto.map((item, index) => {
                return (
                    <>
                        <li
                            key={index}
                            data-aos="fade-up"
                            data-aos-duration="500"
                        >

                            <Link onClick={scrollToTop} to={`/Home/PhotoDetail/` + item.ID}>
                                <div className="photo-card">
                                    <img className="img-fluid rounded-top" alt="..." src={config.images + "photogallery/" + item.Photo_Thumbnail} />
                                    <div className="card-body bg-white rounded-bottom">
                                        <p className="card-text">
                                            {item.Photo_Title.length > 55
                                                ? `${item.Photo_Title.substring(0, 55)}...`
                                                : item.Photo_Title}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colspan="9">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };



    return (
        <>
            <div className="page-head">
                <div className="container">
                    <h3>PHOTO DETAIL</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">PHOTO DETAIL</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section pb-4">
                <div className="container">


                    {loading ? (
                        <div className="card">
                            <div className="card-body p-4">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                        </div>
                    ) : (
                        <>


                            <div class="row gx-3">
                                <div class="col-md-4">
                                    <div class="card">
                                        <img className="events-img rounded-top" alt="..." src={config.images + "photogallery/" + News.Photo_Large} />
                                    </div>
                                </div>
                                <div class="col-md-8  events-detail-card">
                                    <div class="event-body">
                                        <Helmet>
                                            <meta name="title" content={News.Meta_Title} />
                                            <meta name="keywords" content={News.Meta_Keywords} />
                                            <meta name="description" content={News.Meta_Description} />
                                        </Helmet>
                                        <h1>
                                            {News.Photo_Title}
                                        </h1>
                                        <p style={{ fontFamily: "arial,helvetica,sans-serif" }} > {RenderHTMLString(News.Photo_Detail)}</p>

                                    </div>
                                    <div class="date">
                                        <i class="ri-calendar-line"></i> <span>{moment(News.Updated_Date).format('DD-MMM-YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>

            <section className="simple-section pt-3">
                <div className="container">
                    <div className="row align-items-center mb-md-5">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h3>Photo Gallery</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="school text-end">
                                <Link onClick={scrollToTop} className="button-icon button-white" to="/Home/PhotoGallery">
                                    View All <span><i className="ri-arrow-right-s-line"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <div className="card">
                            <div className="card-body p-4">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                        </div>
                    ) : (
                        <>
                            <ul className="responsive news-responsive">
                                {renderPhoto()}
                            </ul>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default PhotoDetail